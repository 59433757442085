// PlacesAutocompleteInput.jsx
import React, { useEffect, useRef } from "react";
import { Input } from "antd";

const PlacesAutocompleteInput = ({ value, onChange }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!window.google) {
      console.error("Google Maps JavaScript API library is not loaded.");
      return;
    }

    // Inicializace Autocomplete bez specifikace 'types'
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current.input
      // Odstranění 'types' umožní vyhledávat všechny typy míst
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        // Zde můžete upravit, jaké informace chcete uživateli zobrazit nebo uložit
        // Například můžete kombinovat název místa a adresu, pokud jsou k dispozici
        const placeDetails =
          place.name && place.formatted_address
            ? `${place.name} - ${place.formatted_address}`
            : place.formatted_address || "";
        onChange(placeDetails);
      } else {
        console.log("No details available for input: " + place.name);
      }
    });
  }, [onChange]);

  return (
    <Input
      ref={inputRef}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Místo události"
    />
  );
};

export default PlacesAutocompleteInput;
