// CompanyNotes.jsx

import React from "react";
import { Button, Input } from "antd";

const CompanyNotes = ({
  notes,
  lastModifiedNotesDate,
  lastModifiedNotesBy,
  handleNotesChange,
  saveNotes,
}) => {
  return (
    <div style={{ marginTop: "15px" }} className="notes">
      <div>
        <h1>Poznámky</h1>
        <span style={{ fontSize: "12px" }}>
          Poslední změna: {lastModifiedNotesDate} od {lastModifiedNotesBy}
        </span>
        <Input.TextArea
          value={notes}
          onChange={handleNotesChange}
          autoSize={{ minRows: 4 }}
          rows={4}
          placeholder="Zde můžete přidat poznámky..."
        />
      </div>

      <div className="notesButton">
        <Button
          className="saveButton"
          style={{ marginTop: "10px", marginBottom: "15px" }}
          onClick={saveNotes}
        >
          Uložit
        </Button>
      </div>
    </div>
  );
};

export default CompanyNotes;
