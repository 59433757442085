import React, { useState } from "react";
import { message } from "antd";
import "./chatgpt.scss";

function ChatGPT() {
  const [userInput, setUserInput] = useState("");
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null); // Přidáno pro ukládání file_id
  const [displayedChat, setDisplayedChat] = useState([]);

  const uploadFile = async () => {
    if (!file) return;

    console.log("Uploading file:", file); // Logování nahrávaného souboru

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("http://ai.lootea.space/upload", {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_ADMIN_API_KEY,
        },
        body: formData,
      });

      const data = await response.json();

      console.log("Upload response:", data);

      if (data.fileId) {
        setFileId(data.fileId); // Uložení file_id pro pozdější použití
        message.success("File uploaded successfully, file ID received.");
        setDisplayedChat((prevDisplayedChat) => [
          ...prevDisplayedChat,
          { role: "system", content: "File uploaded successfully." },
        ]);
      } else {
        message.error("File upload failed.");
      }
    } catch (error) {
      console.error("File upload error:", error);
      message.error("Error uploading file.");
      setDisplayedChat((prevDisplayedChat) => [
        ...prevDisplayedChat,
        { role: "assistant", content: "Error uploading file." },
      ]);
    }

    setFile(null);
  };

  const sendQuery = async () => {
    if (!userInput.trim() || !fileId) return;
    console.log("Sending query:", userInput, "with file ID:", fileId); // Logování odesílaného dotazu

    try {
      const response = await fetch("http://ai.lootea.space/queryFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_ADMIN_API_KEY,
        },
        body: JSON.stringify({ fileId, query: userInput }),
      });

      const data = await response.json();
      console.log("Query response:", data); // Logování odpovědi na dotaz

      if (data.response) {
        setDisplayedChat((prevDisplayedChat) => [
          ...prevDisplayedChat,
          { role: "assistant", content: data.response },
        ]);
      }
    } catch (error) {
      console.error("Query error:", error);
      setDisplayedChat((prevDisplayedChat) => [
        ...prevDisplayedChat,
        { role: "assistant", content: "Error processing query." },
      ]);
    }

    setUserInput("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted"); // Logování odeslání formuláře

    if (file && !fileId) {
      uploadFile();
    } else {
      sendQuery();
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    console.log("File selected:", e.target.files[0]); // Logování výběru souboru
  };

  return (
    <div>
      <div id="chat-history">
        {displayedChat.map((message, idx) => (
          <div key={idx} className={`message ${message.role}`}>
            {message.content}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Napište svůj dotaz..."
        />
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Odeslat</button>
      </form>
    </div>
  );
}

export default ChatGPT;
