import { Form, Input } from "antd";
import React from "react";

const EditableContext = React.createContext(null);

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    dataIndex === "password" ? (
      <Input.Password />
    ) : (
      <Input  />
    );

  return (
    <EditableContext.Consumer>
      {(form) => (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                textAlign: "left", 
              }}
              rules={[
                {
                  required: false,
                  message: `Prosím zadejte ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      )}
    </EditableContext.Consumer>
  );
};

export default EditableCell;
