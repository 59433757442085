import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Space,
  message,
  InputNumber,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import callApi from "../../ApiCaller";
import ImageUpload from "../../components/ImagePicker/ImageUpload";

const { TextArea } = Input;
const { Option } = Select;

const EditRecipeModal = ({
  visible,
  onCancel,
  selectedRecipe,
  onRecipeUpdated,
  setModalVisible,
  selectedProduct,
}) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [isOtherCategory, setIsOtherCategory] = useState(false);
  const [isOtherSeason, setIsOtherSeason] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        try {
          const [
            productsResponse,
            suppliesResponse,
            intermediateProductsResponse,
          ] = await Promise.all([
            callApi("recipes"),
            callApi("supplies"),
            callApi("intermediate-products"),
          ]);

          if (productsResponse.error) {
            message.error(productsResponse.message);
          } else if (suppliesResponse.error) {
            message.error(suppliesResponse.message);
          } else if (intermediateProductsResponse.error) {
            message.error(intermediateProductsResponse.message);
          } else {
            const uniqueCategories = [
              ...new Set(productsResponse.map((product) => product.Category)),
            ];
            const uniqueSeasons = [
              ...new Set(productsResponse.map((product) => product.Season)),
            ];

            // Combine supplies and intermediateProducts into a single list with prefixed IDs
            const combinedIngredients = [
              ...suppliesResponse.map((supply) => ({
                id: `supply-${supply.SupplyID}`,
                name: supply.Name,
                unit: supply.Unit1,
                type: "supply",
              })),
              ...intermediateProductsResponse.map((product) => ({
                id: `intermediate-${product.IntermediateProductID}`,
                name: product.Name,
                unit: product.Unit,
                type: "intermediate",
              })),
            ];

            setCategories(uniqueCategories);
            setSeasons(uniqueSeasons);
            setIngredients(combinedIngredients);
          }
        } catch (error) {
          message.error(error.message);
        }
      };

      fetchData();
    }
  }, [visible]);

  useEffect(() => {
    if (selectedRecipe && selectedProduct) {
      form.setFieldsValue({
        ProductName: selectedProduct.ProductName,
        Category: selectedProduct.Category,
        Season: selectedProduct.Season,
        Active: selectedProduct.Active,
        Description: selectedRecipe.Description,
        Preparation: selectedRecipe.Preparation,
        Note: selectedRecipe.Note,
        ingredients: selectedRecipe.RecipeIngredients?.map((ingredient) => ({
          ingredient: ingredient.SupplyID
            ? `supply-${ingredient.SupplyID}`
            : `intermediate-${ingredient.IntermediateProductID}`,
          quantity: ingredient.Quantity,
          unit: ingredient.Supply
            ? `${ingredient.Supply?.Unit1 || ""} (${
                ingredient.Supply?.Unit2 || ""
              })`
            : ingredient.IntermediateProduct
            ? ingredient.IntermediateProduct.Unit
            : "",
        })),
      });
    }
  }, [selectedRecipe, selectedProduct, form]);

  const handleCategoryChange = (value) => {
    if (value === "Other") {
      setIsOtherCategory(true);
    } else {
      setIsOtherCategory(false);
    }
  };

  const handleSeasonChange = (value) => {
    if (value === "Other") {
      setIsOtherSeason(true);
    } else {
      setIsOtherSeason(false);
    }
  };

  const handleUpdateRecipe = async (values) => {
    if (!selectedRecipe?.RecipeID) {
      message.error("RecipeID is not defined. Please select a valid recipe.");
      return;
    }

    // Prepare ingredients data
    const updatedIngredients = values.ingredients.map((ingredient) => {
      const [type, id] = ingredient.ingredient.split("-");
      const selectedIngredient = ingredients.find(
        (ing) => ing.id === ingredient.ingredient
      );
      return {
        ingredientID: id,
        quantity: ingredient.quantity,
        unit: selectedIngredient ? selectedIngredient.unit : "",
        type: type,
      };
    });

    const data = {
      ...values,
      ingredients: updatedIngredients,
      image: uploadedImage,
    };
    console.log(data);

    try {
      await callApi(`recipes/${selectedRecipe.RecipeID}`, "PUT", data);
      const updatedProducts = await callApi("recipes");
      onRecipeUpdated(updatedProducts);
      onCancel();
      setModalVisible(false);
      message.success("Recept byl úspěšně upraven");
    } catch (error) {
      console.error("Error updating recipe:", error);
      message.error("Chyba při aktualizaci receptu");
    }
  };

  if (!selectedRecipe) {
    return null;
  }

  return (
    <Modal
      title={`Upravit recept`}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleUpdateRecipe}>
        <Form.Item
          name="ProductName"
          label="Název produktu"
          rules={[
            { required: true, message: "Prosím zadejte název produktu!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Category"
          label="Kategorie"
          rules={[{ required: true, message: "Prosím vyberte kategorii!" }]}
        >
          <Select
            showSearch
            placeholder="Vybrat kategorii"
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <Option key={category} value={category}>
                {category}
              </Option>
            ))}
            <Option value="Other">Jiné</Option>
          </Select>
        </Form.Item>
        {isOtherCategory && (
          <Form.Item
            name="OtherCategory"
            label="Jiná Kategorie"
            rules={[
              { required: true, message: "Prosím zadejte jinou kategorii!" },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="Season"
          label="Sezóna"
          rules={[{ required: true, message: "Prosím vyberte sezónu!" }]}
        >
          <Select
            showSearch
            placeholder="Vybrat sezónu"
            onChange={handleSeasonChange}
          >
            {seasons.map((season) => (
              <Option key={season} value={season}>
                {season}
              </Option>
            ))}
            <Option value="Other">Jiné</Option>
          </Select>
        </Form.Item>
        {isOtherSeason && (
          <Form.Item
            name="OtherSeason"
            label="Jiná Sezóna"
            rules={[
              { required: true, message: "Prosím zadejte jinou sezónu!" },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item name="Active" valuePropName="checked">
          <Checkbox>Aktivní</Checkbox>
        </Form.Item>
        <Form.Item name="Description" label="Popis">
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item name="Preparation" label="Postup">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="Note" label="Poznámka">
          <TextArea rows={3} />
        </Form.Item>

        <Form.Item name="image" label="Nahrát obrázek">
          <ImageUpload
            identifier={selectedRecipe?.SizeID}
            identifierType="sizeID"
            onUpdate={(imageId) => setUploadedImage(imageId)}
          />
        </Form.Item>

        <Form.List name="ingredients">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "ingredient"]}
                    rules={[{ required: true, message: "Chybí ingredience" }]}
                    style={{ minWidth: "250px" }}
                  >
                    <Select
                      showSearch
                      placeholder="Vyberte produkt nebo ingredienci"
                      onChange={(value) => {
                        const selectedIngredient = ingredients.find(
                          (ingredient) => ingredient.id === value
                        );
                        form.setFieldsValue({
                          ingredients: form
                            .getFieldValue("ingredients")
                            .map((ing, index) =>
                              index === name
                                ? {
                                    ...ing,
                                    unit: selectedIngredient.unit,
                                  }
                                : ing
                            ),
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ingredients.map((ingredient) => (
                        <Option
                          key={ingredient.id || index}
                          value={ingredient.id}
                        >
                          {ingredient.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "quantity"]}
                    rules={[{ required: true, message: "Chybí množství" }]}
                  >
                    <InputNumber placeholder="Množství" min={0} step={0.01} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "unit"]}
                    style={{
                      display: "flex",
                      width: "70px",
                    }}
                  >
                    <Input placeholder="Jednotka" disabled />
                  </Form.Item>
                  <MinusOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Přidat produkt nebo ingredienci
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Uložit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRecipeModal;
