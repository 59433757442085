import React, { useEffect, useState } from "react";
import {
  Table,
  Descriptions,
  Form,
  Button,
  Popconfirm,
  Input,
  notification,
} from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import EditableCell from "./EditableCell";
import { useSocket } from "../../SocketProvider";
import "./companyinfo.scss";

const CompanyInfo = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [editingKey, setEditingKey] = useState("");
  const [showPasswords, setShowPasswords] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [addingNew, setAddingNew] = useState(false);
  const [infoColumnSortOrder, setInfoColumnSortOrder] = useState("ascend");

  const [isLoading, setIsLoading] = useState(true);
  const socket = useSocket();

  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    if (socket && !socket.connected) {
      socket.connect();
    }
    socket.emit(
      "message",
      JSON.stringify({
        operation_type: "READ",
        database_type: "mariadb",
        table_name: "Password",
      })
    );

    socket.on("data_update", (message) => {
      if (Array.isArray(message.data)) {
        const newData = message.data.map((item) => ({ ...item, key: item.ID }));
        setData(newData);
      } else if (typeof message.data === "object" && message.data !== null) {
        const newData = [{ ...message.data, key: message.data.ID }];
        setData(newData);
      } else {
        console.error("Unexpected data type received:", message.data);
      }
    });

    socket.on("update_confirmed", (message) => {
      const { ID } = message;
      notification.success({
        message: "Aktualizace Úspěšná",
        description: `Záznam s ID ${ID} byl úspěšně aktualizován.`,
        placement: "bottomRight",
      });
    });

    socket.on("insert_confirmed", (message) => {
      const { ID } = message;
      notification.success({
        message: "Přidání Úspěšné",
        description: `Záznam s ID ${ID} byl úspěšně přidán.`,
        placement: "bottomRight",
      });
    });
    socket.on("delete_confirmed", (message) => {
      const { key } = message;
      notification.success({
        message: "Smazání Úspěšné",
        description: `Záznam s ID ${key} byl úspěšně smazán.`,
        placement: "bottomRight",
      });
    });

    setIsLoading(false);
    return () => {
      socket.off("update_confirmed");
      socket.off("insert_confirmed");
      socket.off("delete_confirmed");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const edit = (record) => {
    form.setFieldsValue({
      web: "",
      email: "",
      username: "",
      password: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const fetchDataFromServer = () => {
    socket.emit(
      "message",
      JSON.stringify({
        operation_type: "READ",
        database_type: "mariadb",
        table_name: "Password",
      })
    );
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    const filtered = data.filter((item) => {
      return (
        (item.Info &&
          item.Info.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.Web &&
          item.Web.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.Username &&
          item.Username.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.Email &&
          item.Email.toLowerCase().includes(searchText.toLowerCase()))
        // Přidat další pole, pokud je třeba
      );
    });

    setFilteredData(filtered);
  };

  const handleAdd = () => {
    const newRow = {
      key: `new_${new Date().getTime()}`,
      ID: "",
      Info: "",
      Web: "",
      Username: "",
      Email: "",
      Password: "",
      Notes: "",
    };

    setData((prevData) => [newRow, ...prevData]);
    setEditingKey(newRow.key);
    setAddingNew(true);
    setInfoColumnSortOrder("ascend");
  };

  const handleSave = async (key) => {
    try {
      const stringKey = String(key);
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => stringKey === String(item.key));

      if (stringKey.startsWith("new_")) {
        // Handling new row
        socket.emit(
          "message",
          JSON.stringify({
            operation_type: "INSERT",
            database_type: "mariadb",
            table_name: "Password",
            data: row,
          })
        );
        setEditingKey("");
        setAddingNew(false);
      } else if (index > -1) {
        // Handling existing row
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");

        socket.emit(
          "message",
          JSON.stringify({
            operation_type: "UPDATE",
            database_type: "mariadb",
            table_name: "Password",
            data: { ...item, ...row },
          })
        );
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    fetchDataFromServer();
  };

  const handleCancel = () => {
    // Pokud zrušíme přidání nového řádku, odstraníme ho z dat
    if (addingNew) {
      setData((prevData) => prevData.filter((item) => item.key !== editingKey));
      setAddingNew(false);
    }
    setEditingKey("");
  };

  const handleDelete = (key) => {
    // Handling deletion of an existing row
    socket.emit(
      "message",
      JSON.stringify({
        operation_type: "DELETE",
        database_type: "mariadb",
        table_name: "Password",
        key: key,
      })
    );
    setData((prevData) => prevData.filter((item) => item.key !== key));
  };

  const togglePasswordVisibility = (key) => {
    setShowPasswords((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "ID",
      key: "ID",
      width: 50,
    },
    {
      title: "Info",
      dataIndex: "Info",
      key: "Info",
      editable: true,
      width: 200,
      sorter: (a, b) => {
        if (a.Info === null || a.Info === undefined) a.Info = "";
        if (b.Info === null || b.Info === undefined) b.Info = "";
        return a.Info.localeCompare(b.Info);
      },
      sortOrder: infoColumnSortOrder,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: () => ({
        onClick: () => {
          const newSortOrder =
            infoColumnSortOrder === "ascend" ? "descend" : "ascend";
          setInfoColumnSortOrder(newSortOrder);
        },
      }),
    },
    {
      title: "Web",
      dataIndex: "Web",
      key: "web",
      editable: true,
      width: 200,
    },
    {
      title: "Uživatelské jméno",
      dataIndex: "Username",
      key: "Username",
      editable: true,
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      editable: true,
      width: 200,
    },
    {
      title: "Heslo",
      dataIndex: "Password",
      key: "Password",
      editable: true,
      width: 200,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            style={{ color: "#2a3447" }}
            type="link"
            onClick={() => togglePasswordVisibility(record.key)}
            icon={
              showPasswords[record.key] ? (
                <EyeInvisibleOutlined />
              ) : (
                <EyeOutlined />
              )
            }
          />
          <span style={{ flexGrow: 1 }}>
            {showPasswords[record.key] ? text : ""}
          </span>
        </div>
      ),
    },
    {
      title: "Poznámky",
      dataIndex: "Notes",
      key: "Notes",
      editable: true,
    },
    {
      title: "Akce",
      key: "action",
      width: 150,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => handleSave(record.key)}
              style={{ marginRight: 8, color: "#2a3447" }}
              type="link"
              icon={<CheckOutlined />}
            ></Button>
            <Button
              style={{ color: "#2a3447" }}
              onClick={handleCancel}
              type="link"
              icon={<CloseOutlined />}
            ></Button>
          </span>
        ) : (
          <span>
            <Button
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{ marginRight: 8, color: "#2a3447" }}
              type="link"
              icon={<EditOutlined />}
            ></Button>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              title="Opravdu chcete smazat tento záznam?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button
                style={{ color: "#2a3447" }}
                type="link"
                icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "password" ? "password" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const tableHeader = () => {
    return (
      <div style={{ display: "Flex", gap: 10, marginBottom: 10 }}>
        <Input
          placeholder="Vyhledávání..."
          value={searchText}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
        <Button
          onClick={handleAdd}
          type="primary"
          disabled={addingNew || editingKey !== ""}
        >
          Přidat řádek
        </Button>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <div>
      <h1>Informace</h1>
      <Form form={form} component={false}>
        <Descriptions
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            marginBottom: "10px",
            padding: "20px",
          }}
          title="Společnost"
        >
          <Descriptions.Item label="Název společnosti">
            We Are Lootea s. r. o.
          </Descriptions.Item>
          <Descriptions.Item label="Email">team@lootea.cz</Descriptions.Item>
          <Descriptions.Item label="Sídlo společnosti">
            Rybná 716/24, 110 00, Praha, Česká republika
          </Descriptions.Item>
          <Descriptions.Item label="DIČ">CZ11838787</Descriptions.Item>
          <Descriptions.Item label="EORI">CZ11838787</Descriptions.Item>
          <Descriptions.Item label="DUNS">496851943</Descriptions.Item>
        </Descriptions>
        <Descriptions
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            marginBottom: "10px",
            padding: "20px",
          }}
          title="Pobočka - U Lužického semináře 118/23"
        >
          <Descriptions.Item label="Otevírací doba">
            11:00 - 18:00
          </Descriptions.Item>
          <Descriptions.Item label="Google Maps">
            <a
              href="https://www.google.com/maps?ll=50.089913,14.409942&z=16&t=m&hl=cs&gl=CZ&mapclient=embed&cid=11894744896073414484"
              target="_blank"
              rel="noopener noreferrer"
            >
              Odkaz
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Telefon">
            +420 773 270 042
          </Descriptions.Item>
          <Descriptions.Item label="Adresa">
            U Lužického semináře 118/23, 118 00, Praha, Česká republika
          </Descriptions.Item>
        </Descriptions>

        {tableHeader()}
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={searchText ? filteredData : data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: handleCancel,
          }}
        />
      </Form>
    </div>
  );
};

export default CompanyInfo;
