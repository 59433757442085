import React, { useState, useEffect } from "react";
import { Form, Table, Input, Button, DatePicker } from "antd";
import "jspdf-autotable";
import "./customers.scss";
import callApi from "../../ApiCaller";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([]);

  const handleSearch = () => {
    const searchData = customers.filter((customer) => {
      return Object.values(customer).some((value) =>
        value
          ? value.toString().toLowerCase().includes(searchValue.toLowerCase())
          : false
      );
    });
    setFilteredCustomers(searchData);
  };

  const handleResetSearch = () => {
    setFilteredCustomers(customers);
    setSearchValue("");
  };

  useEffect(() => {
    // Načtení dat z vašeho API
    callApi("customers")
      .then((data) => {
        setCustomers(data);
        setFilteredCustomers(data);
      })
      .catch((error) => {
        console.error("Error loading customers:", error);
      });
  }, []);

  const columns =
    customers.length > 0
      ? [
          {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "Zákazník",
            dataIndex: "Name",
            sorter: (a, b) => (a.Name || "").localeCompare(b.Name || ""),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: customers.map((customer) => ({
              text: customer.Name,
              value: customer.Name,
            })),
            onFilter: (value, record) => (record.Name || "") === value,
          },
          {
            title: "Sleva",
            dataIndex: "Discount",
            sorter: (a, b) =>
              (a.Discount || "").localeCompare(b.Discount || ""),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: customers
              .filter(
                (customer) =>
                  customer.Discount !== null && customer.Discount !== ""
              )
              .map((customer) => ({
                text: customer.Discount,
                value: customer.Discount,
              })),
            onFilter: (value, record) => (record.Discount || "") === value,
          },
          {
            title: "Email",
            dataIndex: "Email",
            sorter: (a, b) => (a.Email || "").localeCompare(b.Email || ""),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: customers
              .filter(
                (customer) => customer.Email !== null && customer.Email !== ""
              )
              .map((customer) => ({
                text: customer.Email,
                value: customer.Email,
              })),
            onFilter: (value, record) => (record.Email || "") === value,
          },
          {
            title: "Telefonní číslo",
            dataIndex: "PhoneNumber",
            sorter: (a, b) =>
              (a.PhoneNumber || "").localeCompare(b.PhoneNumber || ""),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: customers
              .filter(
                (customer) =>
                  customer.PhoneNumber !== null && customer.PhoneNumber !== ""
              )
              .map((customer) => ({
                text: customer.PhoneNumber,
                value: customer.PhoneNumber,
              })),
            onFilter: (value, record) => (record.PhoneNumber || "") === value,
          },
          {
            title: "Vytvořeno",
            dataIndex: "DateCreated",
            sorter: (a, b) => a.DateCreated.localeCompare(b.DateCreated),
            sortDirections: ["ascend", "descend", "ascend"],
            render: (text) => {
              return text ? new Date(text).toLocaleString() : "";
            },
          },
          {
            title: "Upraveno",
            dataIndex: "DateUpdated",
            sorter: (a, b) => a.DateUpdated.localeCompare(b.DateUpdated),
            sortDirections: ["ascend", "descend", "ascend"],
            render: (text) => {
              return text ? new Date(text).toLocaleString() : "";
            },
          },
        ]
      : [];

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    // Implementujte filtrování zákazníků podle vybraného data.
    if (dates && dates.length === 2) {
      const startDate = new Date(dates[0]);
      startDate.setHours(0, 0, 0, 0); // Nastavit začátek dne
      const endDate = new Date(dates[1]);
      endDate.setHours(23, 59, 59, 999); // Nastavit konec dne

      const filteredData = customers.filter((customer) => {
        const createdDate = new Date(customer.DateCreated);
        return createdDate >= startDate && createdDate <= endDate;
      });

      setFilteredCustomers(filteredData);
    } else {
      // Pokud nejsou vybrány žádné datumy, zobrazte všechny zákazníky.
      setFilteredCustomers(customers);
    }
  };
  const data = filteredCustomers.map((customer) => ({
    ...customer,
    key: customer.id,
  }));

  return (
    <div>
      <h1>Zákazníci</h1>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 10,
        }}
      >
        <Form.Item>
          <Search
            placeholder="Hledat"
            onSearch={handleSearch}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            style={{ marginBottom: "5px", maxWidth: "250px" }}
          />
          <Button onClick={handleResetSearch}>Reset</Button>
        </Form.Item>

        <Form.Item>
          <RangePicker
            locale={locale}
            placeholder={["Začátek", "Konec"]}
            onChange={handleDateRangeChange}
            value={dateRange}
          />
        </Form.Item>
      </Form>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSizeOptions: ["10", "20", "50"],
          showSizeChanger: true,
        }}
        footer={() => (
          <div>
            <span>Aktuálně zobrazeno: {data.length} zákazníků</span>
          </div>
        )}
      />
    </div>
  );
};

export default Customers;
