import React, { useState, useEffect } from "react";
import { Button, Tooltip as TooltipAnt } from "antd"; // Import Tooltip from Ant Design
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import callApi from "../../ApiCaller";

const EmployeesGraph = ({ getEmployeeColor }) => {
  const [data, setData] = useState([]);
  const [useAdjustedData, setUseAdjustedData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await callApi("employee-salaries-per-month");

      if (!response.error) {
        const transformedData = transformData(response);
        setData(transformedData);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    };

    fetchData();
  }, [useAdjustedData]);

  const transformData = (apiData) => {
    const months = Array.from(new Set(apiData.map((item) => item.Month)));
    const employeeNames = Array.from(new Set(apiData.map((item) => item.Name)));

    return months.map((month) => {
      const monthData = {
        Month: getCzechMonthName(month.split("-")[1]),
        Total: 0,
      };
      employeeNames.forEach((name) => {
        let salary = 0;
        if (name === "Tomáš Dinh" || name === "Patrik Dinh") {
          salary = useAdjustedData
            ? 40000
            : apiData.find((item) => item.Month === month && item.Name === name)
                ?.TotalSalary || 0;
        } else {
          const employeeData = apiData.find(
            (item) => item.Month === month && item.Name === name
          );
          salary = employeeData ? employeeData.TotalSalary : 0;
        }
        monthData[name] = salary;
        monthData.Total += salary;
      });
      return monthData;
    });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const filteredPayload = payload.filter((item) => item.value > 0);

      if (filteredPayload.length === 0) {
        return null;
      }

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#2a3447",
            border: "1px solid #000",
            padding: "10px",
          }}
        >
          {filteredPayload.map((item) => (
            <p
              key={item.name}
              style={{
                color: item.color,
              }}
            >
              {`${item.name}: ${item.value} Kč`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomAxisTick = ({ x, y, payload }) => {
    const month = payload.value;
    const total = data[payload.index]?.Total || 0;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#C8C8C8">
          {month}
        </text>
        <text
          x={0}
          y={20}
          dy={16}
          textAnchor="middle"
          fill="#FFF"
          fontSize={16}
        >
          {`${total} Kč`}
        </text>
      </g>
    );
  };

  return (
    <div>
      <div style={{ padding: "10px" }}>
        <TooltipAnt title="Jedná se o naší výplatu vs odpracováno.">
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => setUseAdjustedData((prev) => !prev)}
          >
            {useAdjustedData ? "Vyplaceno" : "Odpracováno"}
          </Button>
        </TooltipAnt>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={<CustomAxisTick />} dataKey="Month" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{
              paddingTop: "20px",
            }}
          />
          {data.length > 0 &&
            Object.keys(data[0])
              .filter((key) => key !== "Month" && key !== "Total")
              .map((key) => (
                <Bar
                  key={key}
                  dataKey={key}
                  stackId="a"
                  fill={getEmployeeColor(key)}
                />
              ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const getCzechMonthName = (monthNumber) => {
  const months = [
    "Leden",
    "Únor",
    "Březen",
    "Duben",
    "Květen",
    "Červen",
    "Červenec",
    "Srpen",
    "Září",
    "Říjen",
    "Listopad",
    "Prosinec",
  ];
  return months[parseInt(monthNumber) - 1];
};

export default EmployeesGraph;
