import React from "react";
import "./monthResetButton.scss";

const MonthResetButton = ({ onResetToCurrentMonth }) => {
  return (
    <div className="MonthResetButton">
      <img onClick={onResetToCurrentMonth} src="/rotate-solid.svg" alt="" className="rotateIcon" />
    </div>
  );
};

export default MonthResetButton;