import React, { createContext, useContext, useEffect } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export function SocketProvider({ children }) {
    const socket = io("https://api.lootea.cz", {
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 5000,
        debug: false  // Toto vypne logování na klientovi
    });

    useEffect(() => {
        // Připojení
        socket.on("connect", () => {
            console.log("Connected to WebSocket server");
        });

        // Zachycení chyb připojení
        socket.on("connect_error", (err) => {
            console.error("Connection Error:", err.message); // Případně můžete tuto chybu ignorovat
        });

        // Zachycení obecných chyb
        socket.on("error", (err) => {
            console.error("Socket Error:", err.message); // Tuto chybu můžete také ignorovat nebo logovat jinak
        });

        // Cleanup funkce
        return () => {
            socket.off("connect");
            socket.off("connect_error");
            socket.off("error");
        };
    }, [socket]);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}

export function useSocket() {
    return useContext(SocketContext);
}
