import MonthBigChartBox from "../../components/MonthBigChartBox/MonthBigChartBox";
import YearBigChartBox from "../../components/YearBigChartBox/YearBigChartBox";
import ActualYearChartBox from "../../components/ActualYearChartBox/ActualYearChartBox";
import DeliveryChartBox from "../../components/DeliveryChartBox/DeliveryChartBox";
import PieChartBox from "../../components/pieCartBox/PieChartBox";
import MonthTopCustomerBox from "../../components/MonthTopCustomerBox/MonthTopCustomerBox";
import TopCustomersBox from "../../components/TopCustomersBox/TopCustomersBox";
import CardPayment from "../../components/CardPayment/CardPayment";
import ActualChartbox from "../../components/ActualChartBox/ActualChartbox";
import "./home.scss";

const Home = () => {
  return (
    <div className="home">
      <div className="box box1">
        <MonthTopCustomerBox /> {/* Nejlepší zákazník tohoto měsíce */}
      </div>
      <div className="box box9">
        <ActualChartbox /> {/* Hodinová tržba */}
      </div>
      <div className="box box4">
        <PieChartBox /> {/* Kategorie */}
      </div>
      <div className="box box2">
        <TopCustomersBox /> {/* Nejlepší zákazník  */}
      </div>
      <div className="box box7">
        <MonthBigChartBox /> {/* Denní tržba */}
      </div>

      <div className="box box3"></div>
      <div className="box box10">
        <YearBigChartBox /> {/* Měsíční tržby */}
      </div>

      <div className="box box11">
        <CardPayment />
      </div>
      <div className="box box11">
        <DeliveryChartBox /> {/*  */}
      </div>
      <div className="box box12">
        <ActualYearChartBox /> {/* Roční tržby */}
      </div>
      <div className="box box11"></div>
    </div>
  );
};

export default Home;
