import React, { useState, useEffect } from "react";
import {
  Calendar,
  Modal,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Checkbox,
  notification,
  Select,
} from "antd";
import { useAuth } from "oidc-react";
import dayjs from "dayjs";

import PlacesAutocompleteInput from "../../components/PlacesAutocompleteInput/PlacesAutocompleteInput";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";
import { useSocket } from "../../SocketProvider";
import "./calendar.scss";

import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import CompanyNotes from "../../components/CompanyNotes/CompanyNotes";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const categories = [
  { label: "Pracovní", value: "Pracovní" },
  { label: "Osobní", value: "Osobní" },
  { label: "Ostatní", value: "Ostatní" },
  { label: "Promo akce", value: "Promo akce" },
];

const ManagementCalendar = () => {
  const [isModalVisible, setIsModalOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventPlace, setEventPlace] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [eventStartTime, setEventStartTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [isAllDay, setIsAllDay] = useState(false);
  const [events, setEvents] = useState({});
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [notes, setNotes] = useState("");
  const [lastModifiedNotesBy, setLastModifiedNotesBy] = useState("");
  const [lastModifiedNotesDate, setLastModifiedNotesDate] = useState("");
  const socket = useSocket();
  const auth = useAuth();

  const loadEvents = () => {
    socket.emit("get_calendar_management");
  };

  const handleData = (data) => {
    const transformedEvents = transformEventsData(data);
    setEvents(transformedEvents);
  };
  const fetchCompanyNotes = () => {
    socket.emit("get_company_notes");
  };
  useEffect(() => {
    fetchCompanyNotes();

    // Zaregistrovat posluchače událostí
    socket.on("calendar_management_data", handleData);
    socket.on("calendar_management_event_added", loadEvents);
    socket.on("calendar_management_event_updated", loadEvents);
    socket.on("calendar_management_event_deleted", loadEvents);
    socket.on("company_notes_data", (notesData) => {
      if (notesData.length) {
        setNotes(notesData.map((note) => note.note).join("\n"));
        setLastModifiedNotesBy(
          notesData.map((note) => note.last_modified_by).join("\n")
        );
        const formattedDate = dayjs(
          notesData.map((note) => note.date).join("\n")
        ).format("DD-MM-YYYY HH:mm");
        setLastModifiedNotesDate(formattedDate);
      }
    });
    socket.on("company_note_update_confirmed", () => {
      openNotification("Vaše poznámka byla úspěšně aktualizována.");
    });
    // Počáteční načtení dat
    loadEvents();
    // Odebrání posluchačů při odmontování komponenty
    return () => {
      socket.off("calendar_management_data", handleData);
      socket.off("calendar_management_event_added", loadEvents);
      socket.off("calendar_management_event_updated", loadEvents);
      socket.off("calendar_management_event_deleted", loadEvents);
      socket.off("company_note_update_confirmed", loadEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const transformEventsData = (data) => {
    return data.reduce((acc, event) => {
      // Převedení startovního a koncového data na objekty dayjs a lokalizace
      const startDate = dayjs
        .utc(event.start_date)
        .local()
        .format("YYYY-MM-DD");
      const endDate = dayjs.utc(event.end_date).local().format("YYYY-MM-DD");

      // Zjistěte, zda událost probíhá více dní
      let currentDate = startDate;
      while (currentDate <= endDate) {
        if (!acc[currentDate]) {
          acc[currentDate] = [];
        }

        acc[currentDate].push({
          id: event.id,
          title: event.title,
          text: event.text,
          startDate: event.start_date,
          endDate: event.end_date,
          category: event.category,
          place: event.place,
        });

        // Posun na další den
        currentDate = dayjs(currentDate).add(1, "day").format("YYYY-MM-DD");
      }

      return acc;
    }, {});
  };

  const openNotification = (message) => {
    notification.success({
      message: "Aktualizace",
      description: message,
      placement: "bottomRight",
      duration: 2,
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setEventTitle("");
    setEventText("");
    setEventPlace("");
    setEventCategory("");
    setEventDate(null);
    setEventStartTime(null);
    setEventEndTime(null);
    setIsAllDay(false);
    setEditingEvent(null);
  };

  const handleOk = () => {
    if (!eventDate || !eventTitle || !eventCategory) {
      notification.error({
        message: "Chyba",
        description: "Nadpis, kategorie a datum jsou povinné.",
        placement: "bottomRight",
        duration: 2,
      });
      return;
    }

    const startDate = eventDate[0].format("YYYY-MM-DD");
    const endDate = eventDate[1].format("YYYY-MM-DD");

    const eventToAddOrUpdate = {
      id: editingEvent ? editingEvent.id : `temp-${Date.now()}`,
      title: eventTitle,
      place: eventPlace,
      category: eventCategory,
      start_date:
        startDate +
        " " +
        (isAllDay
          ? "00:00:00"
          : eventStartTime
          ? eventStartTime.format("HH:mm:ss")
          : "00:00:00"),
      end_date:
        endDate +
        " " +
        (isAllDay
          ? "23:59:59"
          : eventEndTime
          ? eventEndTime.format("HH:mm:ss")
          : "23:59:59"),

      edited_by: `${auth.userData?.profile?.given_name} ${auth.userData?.profile?.family_name}`,
      text: eventText,
    };

    if (editingEvent) {
      socket.emit(
        "update_calendar_management",
        eventToAddOrUpdate,
        (response) => {
          loadEvents();
        }
      );
    } else {
      socket.emit("add_calendar_management", eventToAddOrUpdate, (response) => {
        loadEvents();
      });
    }

    setIsModalOpen(false);
    resetForm();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const handleDelete = (eventId) => {
    socket.emit("delete_event_calendar_management", { id: eventId });
    setSelectedEventDetails(null);
  };

  const handleDeleteConfirm = (eventId) => {
    Modal.confirm({
      title: "Opravdu chcete smazat tuto událost?",
      content: "Tuto akci nelze vrátit zpět.",
      okText: "Ano, smazat",
      okType: "danger",
      cancelText: "Ne, zrušit",
      onOk() {
        handleDelete(eventId);
      },
    });
  };

  const handleNotesChange = (event) => {
    const newNotes = event.target.value;
    setNotes(newNotes);
  };

  const saveNotes = () => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    const dataToSend = {
      operation_type: "UPDATE",
      database_type: "mariadb",
      table_name: "Company_notes",
      data: {
        id: 1,
        note: notes,
        date: formattedDate,
        last_modified_by:
          `${auth.userData?.profile?.given_name} ${auth.userData?.profile?.family_name}` ||
          "undefined user",
      },
      type: "update_company_notes",
    };
    socket.emit("message", JSON.stringify(dataToSend)); // websocket
  };

  const openEditModal = (event) => {
    setEventTitle(event.title);
    setEventPlace(event.place);
    setEventCategory(event.category);
    setEventDate([dayjs(event.startDate), dayjs(event.endDate)]);
    setEventStartTime(dayjs(event.startDate));
    setEventEndTime(dayjs(event.endDate));
    setIsAllDay(event.isAllDay);
    setEditingEvent(event);
    setIsModalOpen(true);
    setEventText(event.text);
    setSelectedEventDetails(null);
  };

  const cellRender = (value) => {
    const dateKey = value.format("YYYY-MM-DD");

    // Funkce pro otevření modálního okna pro přidání události
    const openAddEventModal = (date) => {
      setEventDate([date, date]);
      showModal();
    };
    // Funkce pro zpracování kliknutí na konkrétní událost, zabraňuje bublání události
    const handleEventClick = (event, e) => {
      e.stopPropagation(); // Zabraňuje vyvolání kliknutí na celou buňku
      openEventDetails(event);
    };
    if (events[dateKey]) {
      const sortedEvents = [...events[dateKey]].sort((a, b) => {
        if (a.isAllDay && !b.isAllDay) {
          return -1;
        } else if (!a.isAllDay && b.isAllDay) {
          return 1;
        } else {
          return a.startTime - b.startTime;
        }
      });

      return (
        <div
          onClick={() => openAddEventModal(value)}
          style={{ cursor: "pointer", height: "100%", padding: "5px" }} // Přizpůsobte styly podle potřeby
        >
          {" "}
          <ul className="calendar-events">
            {sortedEvents
              .slice() // Vytvoří kopii pole, aby se nezměnilo původní
              .sort((a, b) => {
                // Určení, zda jsou události celodenní
                const isAllDayA =
                  dayjs.utc(a.startDate).local().format("HH:mm") === "00:00" &&
                  dayjs.utc(a.endDate).local().format("HH:mm") === "23:59";
                const isAllDayB =
                  dayjs.utc(b.startDate).local().format("HH:mm") === "00:00" &&
                  dayjs.utc(b.endDate).local().format("HH:mm") === "23:59";

                if (isAllDayA && !isAllDayB) return 1;
                if (!isAllDayA && isAllDayB) return -1;

                // Seřadit podle času začátku, pokud nejsou obě celodenní
                return dayjs.utc(a.startDate).diff(dayjs.utc(b.startDate));
              })
              .map((event, index) => (
                <li key={index} onClick={(e) => handleEventClick(event, e)}>
                  <div
                    className="events"
                    style={{ backgroundColor: getEventColor(event.category) }}
                  >
                    <span>
                      <strong>{event.title}</strong>
                    </span>
                    <span>
                      {dayjs.utc(event.startDate).local().format("HH:mm") ===
                        "00:00" &&
                      dayjs.utc(event.endDate).local().format("HH:mm") ===
                        "23:59"
                        ? ""
                        : `${dayjs
                            .utc(event.startDate)
                            .local()
                            .format("HH:mm")} - ${dayjs
                            .utc(event.endDate)
                            .local()
                            .format("HH:mm")}`}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      );
    }
    return (
      <div
        onClick={() => openAddEventModal(value)}
        style={{ cursor: "pointer", height: "100%", padding: "5px" }}
      />
    );
  };

  const getEventColor = (category) => {
    switch (category) {
      case "Pracovní":
        return "#ADD8E6"; // světle modrá
      case "Osobní":
        return "#F88379"; // světle červená
      case "Ostatní":
        return "#90EE90"; // světle zelená
      case "Promo akce":
        return "#FDFD96"; // světle žlutá
      default:
        return "#FFFFFF"; // defaultní barva
    }
  };

  const openEventDetails = (event) => {
    setSelectedEventDetails({
      ...event,
      text: event.text,
      category: event.category,
      edited_by: event.edited_by,
      start_date: event.startDate,
      end_date: event.endDate,
      edited_at: event.edited_at,
      place: event.place,
    });
  };
  return (
    <div>
      <div className="CompanyNotes">
        <CompanyNotes
          notes={notes}
          lastModifiedNotesDate={lastModifiedNotesDate}
          lastModifiedNotesBy={lastModifiedNotesBy}
          handleNotesChange={handleNotesChange}
          saveNotes={saveNotes}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h1>Kalendář</h1>
        <div
          style={{ marginLeft: "5px", cursor: "pointer", fontSize: "22px" }}
          onClick={showModal}
        >
          ➕
        </div>
      </div>
      <div className="management-calendar">
        <Calendar locale={locale} cellRender={cellRender} />
      </div>
      {/* ShowInfo */}
      <Modal
        title="Detaily události"
        open={selectedEventDetails !== null}
        onCancel={() => setSelectedEventDetails(null)}
        footer={[
          <Button
            key="delete"
            type="danger"
            onClick={() => handleDeleteConfirm(selectedEventDetails.id)}
          >
            Smazat
          </Button>,
          <Button
            key="edit"
            type="primary"
            onClick={() => openEditModal(selectedEventDetails)}
          >
            Upravit
          </Button>,
        ]}
      >
        {selectedEventDetails && (
          <div>
            <p>
              <strong>Název:</strong> {selectedEventDetails.title}
            </p>
            <p>
              <strong>Místo:</strong> {selectedEventDetails.place}
            </p>
            {selectedEventDetails && selectedEventDetails.place && (
              <iframe
                title="Google Maps"
                loading="lazy"
                width="470"
                height="250"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
                  selectedEventDetails.place
                )}&key=AIzaSyCfNxen8KvjjQoA5Px4a4Cu1fUr2ek4uoo`}
                allowFullScreen
              />
            )}
            <p>
              <strong>Kategorie:</strong> {selectedEventDetails.category}
            </p>
            <p>
              <strong>Datum:</strong>{" "}
              {dayjs
                .utc(selectedEventDetails.startDate)
                .local()
                .format("DD. MM.")}{" "}
              -{" "}
              {dayjs
                .utc(selectedEventDetails.endDate)
                .local()
                .format("DD. MM.")}
            </p>
            <p>
              <strong>Čas:</strong>{" "}
              {dayjs
                .utc(selectedEventDetails.start_date)
                .local()
                .format("HH:mm") === "00:00" &&
              dayjs
                .utc(selectedEventDetails.end_date)
                .local()
                .format("HH:mm") === "23:59"
                ? "Celý den"
                : `${dayjs
                    .utc(selectedEventDetails.start_date)
                    .local()
                    .format("HH:mm")} - ${dayjs
                    .utc(selectedEventDetails.end_date)
                    .local()
                    .format("HH:mm")}`}
            </p>
            <p>
              <strong>Poznámka:</strong> {selectedEventDetails.text}
            </p>
          </div>
        )}
      </Modal>

      {/* Edit/Add */}
      <Modal
        title={editingEvent ? "Upravit událost" : "Nová událost"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="calendar-event-modal">
          <Input
            placeholder="Nadpis události"
            className="calendar-event-input"
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
          />

          <PlacesAutocompleteInput
            value={eventPlace}
            onChange={setEventPlace}
          />

          <Select
            placeholder="Vyberte kategorii"
            value={eventCategory}
            onChange={(value) => setEventCategory(value)}
            style={{ width: "100%" }}
          >
            {categories.map((category) => (
              <Option key={category.value} value={category.value}>
                {category.label}
              </Option>
            ))}
          </Select>
          <RangePicker
            locale={locale}
            className="calendar-event-range-picker"
            value={eventDate}
            onChange={(date) => setEventDate(date)}
          />
          <Checkbox
            checked={isAllDay}
            className="calendar-event-checkbox"
            onChange={(e) => setIsAllDay(e.target.checked)}
            style={{ marginLeft: "5px" }}
          >
            Celý den
          </Checkbox>
          {!isAllDay && (
            <>
              <TimePicker
                className="calendar-event-time-picker"
                locale={locale}
                value={eventStartTime}
                changeOnScroll
                needConfirm={false}
                format={"HH:mm"}
                onChange={(time) => {
                  setEventStartTime(time);
                }}
              />
              <TimePicker
                locale={locale}
                value={eventEndTime}
                changeOnScroll
                format={"HH:mm"}
                needConfirm={false}
                onChange={(time) => setEventEndTime(time)}
              />
            </>
          )}
          <TextArea
            placeholder="Poznámka události"
            className="calendar-event-input"
            value={eventText}
            autoSize={true}
            onChange={(e) => setEventText(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ManagementCalendar;
