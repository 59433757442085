// DateRangeSelector.js
import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import "dayjs/locale/cs";

const DateRangeSelector = ({ onDateChange }) => {
  const selectPeriod = (periodType) => {
    let start, end;

    switch (periodType) {
      case 'yesterday':
        start = dayjs().startOf('yesterday').add(-1, 'day');;
        end = dayjs().endOf('yesterday').add(-1, 'day');;
        break;
      case 'today':
        start = dayjs().startOf('today');;
        end = dayjs().endOf('today');;
        break;
      case 'thisWeek':
        start = dayjs().startOf('week')
        end = dayjs().endOf('week')
        break;
      case 'lastWeek':
        start = dayjs().subtract(1, 'week').startOf('week')
        end = dayjs().subtract(1, 'week').endOf('week')
        break;
      case 'thisYear':
        start = dayjs().startOf('year');
        end = dayjs().endOf('year');
        break;
      case 'lastYear':
        start = dayjs().subtract(1, 'year').startOf('year');
        end = dayjs().subtract(1, 'year').endOf('year');
        break;
      case 'thisMonth':
        start = dayjs().startOf('month');
        end = dayjs().endOf('month');
        break;
      case 'lastMonth':
        start = dayjs().subtract(1, 'month').startOf('month');
        end = dayjs().subtract(1, 'month').endOf('month');
        break;
      default:
        start = dayjs();
        end = dayjs();
    }

    onDateChange([start, end], [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")]);
  };
  return (
    <div>
      <Button onClick={() => selectPeriod('today')} style={{}}>
        Dnes
      </Button>
      <Button onClick={() => selectPeriod('yesterday')} style={{ margin: '0px 0px 0px 4px' }}>
        Včera
      </Button>
      <Button onClick={() => selectPeriod('thisWeek')} style={{ margin: '0px 0px 0px 4px' }}>
        Tento týden
      </Button>
      <Button onClick={() => selectPeriod('lastWeek')} style={{ margin: '0 0px 0px 4px' }}>
        Minulý týden
      </Button>
      <Button onClick={() => selectPeriod('thisMonth')} style={{ margin: '0 0px 0px 4px' }}>
        Tento měsíc
      </Button>
      <Button onClick={() => selectPeriod('lastMonth')} style={{ margin: '0 0px 0px 4px' }}>
        Minulý měsíc
      </Button>
      <Button onClick={() => selectPeriod('thisYear')} style={{ margin: '0 0px 0px 4px' }}>
        Tento rok
      </Button>
      <Button onClick={() => selectPeriod('lastYear')} style={{ margin: '0 0px 0px 4px' }}>
        Minulý rok
      </Button>
    </div>
  );
};

export default DateRangeSelector;
