import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SocketProvider } from "./SocketProvider";
import { AuthProvider, useAuth } from "oidc-react";


import ServerStatusBar from './components/serverStatusBar/ServerStatusBar';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Menu from './components/menu/Menu';
import './styles/global.scss';

import Home from './pages/Home/Home';
import Customers from './pages/Customers/Customers';
import Products from './pages/Products/Products';
import User from './pages/User/User';
import Product from './pages/Product/Product';
import Documents from './pages/Documents/Documents';
import ManagementCalendar from './pages/Calendar/Calendar';
import SalesByProducts from './pages/SalesByProducts/SalesByProducts';
import Dailysales from './pages/DailySales/Dailysales';
import Backups from './pages/Backups/Backups'
import Settings from './pages/Settings/Settings'
import CompanyInfo from './pages/CompanyInfo/CompanyInfo';
import PaymentTypes from './pages/PaymentTypes/PaymentTypes';
import ChatGPT from './pages/ChatGPT/Chatgpt';
import Employees from './pages/Employees/Employees';
import ShiftSheduler from './pages/ShiftSheduler/ShiftSheduler';
import DailyClosure from './pages/DailyClosure/DailyClosure';
import Login from './pages/Login/Login';
import HomeEmployee from './pages/HomeEmployee/HomeEmployee';
import Recipe from './pages/Recipe/Recipe';
import FAQ from "./pages/FAQ/FAQ"
import SalesByTaxes from "./pages/SalesByTaxes/SalesByTaxes"
import PosTransaction from "./pages/PosTransaction/PosTransaction"
import Invoices from "./pages/Invoices/Invoices"
import BankTransaction from "./pages/BankTransaction/BankTransaction"
import Storage from './pages/Storage/Storage';

const queryClient = new QueryClient();

const isProduction = window.location.hostname === 'admin.lootea.cz';

const oidcConfig = {
  authority: "https://security.lootea.cz",
  clientId: "242944451771723779@dashboard",
  responseType: "code",
  redirectUri: isProduction ? "https://admin.lootea.cz" : "http://localhost:3000",
  scope: "openid profile email urn:zitadel:iam:org:project:id:zitadel:aud "
};
const Layout = () => {
  const [role, setRole] = useState("admin"); // Default to admin

  const auth = useAuth();
  const isAuthenticated = auth.userData && !auth.isLoading;
  const userProfile = auth.userData?.profile;
  const isInvestor = userProfile?.["urn:zitadel:iam:org:project:242942895919755267:roles"]
    ?.some(role => role.investor);

  return (
    <div className="main">
      {isAuthenticated && <ServerStatusBar />}
      {isAuthenticated && <Navbar setRole={setRole} />}
      <div className="container">
        {isAuthenticated && !isInvestor && (
          <div className="menuContainer">
            <Menu key={role} role={role} />          </div>
        )}
        <div className="contentContainer">
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </div>
      </div>
      {isAuthenticated && (<Footer />)}
    </div>
  );
};


function ProtectedRoute({ children }) {
  const auth = useAuth();
  const isAuthenticated = auth.userData && !auth.isLoading;
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.["urn:zitadel:iam:org:project:242942895919755267:roles"]
    ?.some(role => role.employee);
  const isInvestor = userProfile?.["urn:zitadel:iam:org:project:242942895919755267:roles"]
    ?.some(role => role.investor);


  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  if (isEmployee) {
    return children ? children : null;
  }
  if (isInvestor) {
    return children ? children : null;
  }

  return children ? children : <Outlet />;
}


function ProtectedRoutes() {
  const auth = useAuth();
  const isAuthenticated = auth.userData && !auth.isLoading;

  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.["urn:zitadel:iam:org:project:242942895919755267:roles"]
    ?.some(role => role.employee);
  const isInvestor = userProfile?.["urn:zitadel:iam:org:project:242942895919755267:roles"]
    ?.some(role => role.investor);

  const defaultRoute = isEmployee ? <HomeEmployee /> : <Home />;
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={isAuthenticated ? defaultRoute : <Login />} />
          {isInvestor && (
            <>
            </>
          )}
          {isEmployee && (
            <>
              <Route path="/homeemployee" element={<ProtectedRoute><HomeEmployee /></ProtectedRoute>} />
              <Route path="/DailyClosure" element={<ProtectedRoute><DailyClosure /></ProtectedRoute>} />
              <Route path="/recipes" element={<ProtectedRoute><Recipe /></ProtectedRoute>} />
              <Route path="/shift-sheduler" element={<ProtectedRoute><ShiftSheduler /></ProtectedRoute>} />
              <Route path="/faq" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
              <Route path="/storage" element={<ProtectedRoute><Storage /></ProtectedRoute>} />
            </>
          )}
          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/products" element={<Products />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/customers/:id" element={<User />} />
            <Route path="/products/:id" element={<Product />} />
            <Route path="/documents/:id" element={<Documents />} />
            <Route path="/calendar" element={<ManagementCalendar />} />
            <Route path="/daily-sales" element={<Dailysales />} />
            <Route path="/sales-by-products" element={<SalesByProducts />} />
            <Route path="/backups" element={<Backups />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/company-info" element={<CompanyInfo />} />
            <Route path="/payment-types" element={<PaymentTypes />} />
            <Route path="/chatgpt" element={<ChatGPT />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/shift-sheduler" element={<ShiftSheduler />} />
            <Route path="/homeemployee" element={<ProtectedRoute><HomeEmployee /></ProtectedRoute>} />
            <Route path="/DailyClosure" element={<ProtectedRoute><DailyClosure /></ProtectedRoute>} />
            <Route path="/recipes" element={<ProtectedRoute><Recipe /></ProtectedRoute>} />
            <Route path="/faq" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
            <Route path="/sales-by-taxes" element={<ProtectedRoute><SalesByTaxes /></ProtectedRoute>} />
            <Route path="/pos-transaction" element={<ProtectedRoute><PosTransaction /></ProtectedRoute>} />
            <Route path="/bank-transaction" element={<ProtectedRoute><BankTransaction /></ProtectedRoute>} />
            <Route path="/storage" element={<ProtectedRoute><Storage /></ProtectedRoute>} />
            <Route path="/invoices" element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}



function App() {
  return (
    <BrowserRouter>
      <SocketProvider>
        <AuthProvider {...oidcConfig}>
          <ProtectedRoutes />
        </AuthProvider>
      </SocketProvider>

    </BrowserRouter >
  );
}

export default App;
