import React from "react";
import { Button } from "antd";
import { ZoomInOutlined } from "@ant-design/icons";

export const getColumns = (openDrawer) => [
  {
    title: "DPH",
    dataIndex: "Tax",
    sorter: (a, b) => a.Tax - b.Tax,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Id",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Číslo dokladu",
    dataIndex: "Number",
    sorter: (a, b) => (a.Number || "").localeCompare(b.Number || ""),

    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Číslo Externího dokladu",
    dataIndex: "ReferenceDocumentNumber",
    sorter: (a, b) => {
      const valueA = a.ReferenceDocumentNumber || "";
      const valueB = b.ReferenceDocumentNumber || "";
      return valueA.localeCompare(valueB);
    },
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Zákazník",
    dataIndex: "Customer",
    sorter: (a, b) => (a.Customer || "").localeCompare(b.Customer || ""),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Tržba (Kč)",
    dataIndex: "Total",
    render: (text) => `${text.toFixed(2)} Kč`,
    sorter: (a, b) => a.Total - b.Total,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Sleva (Kč)",
    dataIndex: "Discount",
    render: (text, record) => {
      if (record.DiscountType === 0) {
        // Pokud je typ slevy 0 (procentuální), přepočítejte na částku z původní ceny
        const originalPrice =
          parseFloat(record.Total) / (1 - parseFloat(text) / 100);
        return `${(originalPrice - parseFloat(record.Total)).toFixed(2)} Kč`;
      }
      return `${text} Kč`;
    },
    sorter: (a, b) => {
      if (a.DiscountType === 0 && b.DiscountType === 0) {
        // Pokud je typ slevy 0 (procentuální), porovnávejte podle částky
        const originalPriceA =
          parseFloat(a.Total) / (1 - parseFloat(a.Discount) / 100);
        const originalPriceB =
          parseFloat(b.Total) / (1 - parseFloat(b.Discount) / 100);
        return originalPriceA - originalPriceB;
      }
      return parseFloat(a.Discount) - parseFloat(b.Discount);
    },
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Typ platby",
    dataIndex: "PaymentType",
    render: (text) => `${text}`,
    sorter: (a, b) => (a.PaymentType || "").localeCompare(b.PaymentType || ""),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Typ dokumentu",
    dataIndex: "DocumentType",
    render: (text) => `${text}`,
    sorter: (a, b) =>
      (a.DocumentType || "").localeCompare(b.DocumentType || ""),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Obsluha",
    dataIndex: "User",
    render: (text) => `${text}`,
    sorter: (a, b) => (a.User || "").localeCompare(b.User || ""),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Vytvořeno",
    dataIndex: "DateCreated",
    render: (text) => {
      return text ? new Date(text).toLocaleString() : "";
    },
    sorter: (a, b) => new Date(a.DateCreated) - new Date(b.DateCreated),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Upraveno",
    dataIndex: "DateUpdated",
    render: (text) => {
      return text ? new Date(text).toLocaleString() : "";
    },
    sorter: (a, b) => new Date(a.DateUpdated) - new Date(b.DateUpdated),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Akce",
    dataIndex: "actions",
    render: (text, record) => (
      <Button type="link" onClick={() => openDrawer(record)}>
        <ZoomInOutlined style={{ fontSize: "15px", color: "#222b3c" }} />
      </Button>
    ),
  },
];
