import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Select, Space } from "antd";
import callApi from "../../ApiCaller";
import ImageUpload from "../../components/ImagePicker/ImageUpload";

const { Option } = Select;

const EditIngredientModal = ({ visible, onCancel, onUpdate, ingredient }) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [types, setTypes] = useState([]);
  const [addingNewCategory, setAddingNewCategory] = useState(false);
  const [addingNewUnit, setAddingNewUnit] = useState(false);
  const [addingNewType, setAddingNewType] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi("supplies", "GET");
        if (response.error) {
          message.error("Chyba při načítání dat");
        } else {
          const uniqueCategories = [
            ...new Set(response.map((supply) => supply.Category)),
          ];
          const uniqueUnits = [
            ...new Set(
              response.flatMap((supply) => [supply.Unit1, supply.Unit2])
            ),
          ];
          const uniqueTypes = [
            ...new Set(response.map((supply) => supply.Type)),
          ];
          setCategories(uniqueCategories);
          setUnits(uniqueUnits);
          setTypes(uniqueTypes);
        }
      } catch (error) {
        message.error("Chyba při načítání dat");
      }
    };

    fetchData();

    if (ingredient && visible) {
      form.setFieldsValue({
        name: ingredient.Name || "",
        category: ingredient.Category || "",
        unit1: ingredient.Unit1 || "",
        unit2: ingredient.Unit2 || "",
        conversionFactor:
          ingredient.ConversionFactor !== undefined
            ? ingredient.ConversionFactor
            : "",
        type: ingredient.Type || "",
        image: ingredient.ImageURL || "",
      });
      setUploadedImage(ingredient.ImageURL || null);
      setThumbnailImage(ingredient.ThumbnailURL || null);
    }
  }, [ingredient, form, visible]);

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      image: uploadedImage,
      thumbnail: thumbnailImage,
    };

    try {
      const response = await callApi(
        `supplies/${ingredient.SupplyID}`,
        "PUT",
        data
      );
      if (response.error) {
        message.error("Chyba při aktualizaci ingredience");
      } else {
        message.success("Ingredience úspěšně aktualizována");
        onUpdate();
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      message.error("Chyba při aktualizaci ingredience");
    }
  };

  const handleImageUpdate = (imageData) => {
    setUploadedImage(imageData.fullSizeUrl);
    setThumbnailImage(imageData.thumbnailUrl);
  };

  return (
    <Modal
      title="Upravit ingredienci"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Název ingredience"
          rules={[
            { required: true, message: "Prosím vložte název ingredience!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Kategorie"
          rules={[
            { required: true, message: "Prosím vložte kategorii ingredience!" },
          ]}
        >
          {addingNewCategory ? (
            <Input
              placeholder="Nová kategorie"
              onBlur={() => setAddingNewCategory(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte kategorii"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewCategory(true);
                  form.setFieldsValue({ category: "" });
                }
              }}
            >
              {categories.map((category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="unit1"
          label="Měrná jednotka 1"
          rules={[
            { required: true, message: "Prosím vložte měrnou jednotku!" },
          ]}
        >
          {addingNewUnit ? (
            <Input
              placeholder="Nová měrná jednotka"
              onBlur={() => setAddingNewUnit(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte měrnou jednotku"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewUnit(true);
                  form.setFieldsValue({ unit1: "" });
                }
              }}
            >
              {units.map((unit) => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="unit2"
          label="Měrná jednotka 2"
          rules={[
            { required: true, message: "Prosím vložte měrnou jednotku!" },
          ]}
        >
          {addingNewUnit ? (
            <Input
              placeholder="Nová měrná jednotka"
              onBlur={() => setAddingNewUnit(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte měrnou jednotku"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewUnit(true);
                  form.setFieldsValue({ unit2: "" });
                }
              }}
            >
              {units.map((unit) => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="conversionFactor"
          label="Konverzní Faktor"
          rules={[
            { required: true, message: "Prosím vložte konverzní faktor!" },
          ]}
        >
          <Input type="number" step="0.00001" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Typ"
          rules={[
            { required: true, message: "Prosím vložte typ ingredience!" },
          ]}
        >
          {addingNewType ? (
            <Input
              placeholder="Nový typ"
              onBlur={() => setAddingNewType(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte typ"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewType(true);
                  form.setFieldsValue({ type: "" });
                }
              }}
            >
              {types.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item name="image" label="Nahrát obrázek">
          <ImageUpload
            identifier={ingredient?.SupplyID}
            identifierType="supplyID"
            onUpdate={handleImageUpdate}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Uložit
            </Button>
            <Button onClick={onCancel}>Zrušit</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditIngredientModal;
