import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Alert,
  Space,
  message,
} from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import callApi from "../../ApiCaller";

const { Option } = Select;

const AddNewProductModal = ({ visible, onCancel, onSubmit }) => {
  const [categories, setCategories] = useState([]);
  const [variations, setVariations] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const [isOtherCategory, setIsOtherCategory] = useState(false);
  const [isOtherSeason, setIsOtherSeason] = useState(false);
  const [isOtherVariation, setIsOtherVariation] = useState(false);
  const [isOtherSize, setIsOtherSize] = useState(false);

  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        try {
          const [
            productsResponse,
            suppliesResponse,
            intermediateProductsResponse,
          ] = await Promise.all([
            callApi("recipes"),
            callApi("supplies"),
            callApi("intermediate-products"),
          ]);

          if (productsResponse.error) {
            setError(productsResponse.message);
          } else if (suppliesResponse.error) {
            setError(suppliesResponse.message);
          } else if (intermediateProductsResponse.error) {
            setError(intermediateProductsResponse.message);
          } else {
            const uniqueCategories = [
              ...new Set(productsResponse.map((product) => product.Category)),
            ];
            const uniqueSeasons = [
              ...new Set(productsResponse.map((product) => product.Season)),
            ];
            const uniqueVariations = [
              ...new Set(
                productsResponse
                  .flatMap((product) => product.ProductVariations)
                  .map((variation) => variation.Variation)
              ),
            ];
            const uniqueSizes = [
              ...new Set(
                productsResponse
                  .flatMap((product) => product.ProductVariations)
                  .flatMap((variation) => variation.ProductSizes)
                  .map((size) => size.Size)
              ),
            ];

            // Combine supplies and intermediateProducts into a single list
            const combinedIngredients = [
              ...suppliesResponse.map((supply) => ({
                id: supply.SupplyID,
                name: supply.Name,
                unit: supply.Unit,
                type: "supply",
              })),
              ...intermediateProductsResponse.map((product) => ({
                id: product.IntermediateProductID,
                name: product.Name,
                unit: product.Unit,
                type: "intermediate",
              })),
            ];

            setCategories(uniqueCategories);
            setSeasons(uniqueSeasons);
            setVariations(uniqueVariations);
            setSizes(uniqueSizes);
            setIngredients(combinedIngredients);
          }
        } catch (error) {
          setError(error.message);
        }
      };

      fetchData();
    }
  }, [visible]);

  const handleCategoryChange = (value) => {
    if (value === "Other") {
      setIsOtherCategory(true);
    } else {
      setIsOtherCategory(false);
    }
  };

  const handleSeasonChange = (value) => {
    if (value === "Other") {
      setIsOtherSeason(true);
    } else {
      setIsOtherSeason(false);
    }
  };

  const handleVariationChange = (value) => {
    if (value === "Other") {
      setIsOtherVariation(true);
    } else {
      setIsOtherVariation(false);
    }
  };

  const handleSizeChange = (value) => {
    if (value === "Other") {
      setIsOtherSize(true);
    } else {
      setIsOtherSize(false);
    }
  };

  const handleSubmit = async (values) => {
    const ingredients = (values.ingredients || []).map((ingredient) => {
      const ingredientID = ingredient.ingredient;
      const quantity = ingredient.quantity;
      const unit = ingredient.unit;
      return { ingredientID, quantity, unit };
    });

    const data = {
      ...values,
      ingredients,
    };

    try {
      const response = await callApi("products", "POST", data);
      if (response.error) {
        setError(response.message);
        message.error("Failed to add product");
      } else {
        message.success("Product added successfully");
        onSubmit();
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      setError(error.message);
      message.error("Failed to add product");
    }
  };

  if (error) {
    return <Alert message={`Error: ${error}`} type="error" />;
  }

  return (
    <Modal
      title="Přidat nový produkt"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          Active: true,
          ingredients: [],
        }}
      >
        <Form.Item
          name="ProductName"
          label="Název produktu"
          rules={[{ required: true, message: "Prosím vlož název produktu!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Category"
          label="Kategorie"
          rules={[{ required: true, message: "Prosím vyber kategorii!" }]}
        >
          <Select
            showSearch
            placeholder="Vybrat kategorii"
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <Option key={category} value={category}>
                {category}
              </Option>
            ))}
            <Option value="Other">Jiné</Option>
          </Select>
        </Form.Item>
        {isOtherCategory && (
          <Form.Item
            name="OtherCategory"
            label="Jiná Kategorie"
            rules={[
              { required: true, message: "Prosím zadejte jinou kategorii!" },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="Variation"
          label="Variace"
          rules={[{ required: true, message: "Prosím vyber jednu z variací!" }]}
        >
          <Select
            showSearch
            placeholder="Vybrat variaci"
            onChange={handleVariationChange}
          >
            {variations.map((variation) => (
              <Option key={variation} value={variation}>
                {variation}
              </Option>
            ))}
            <Option value="Other">Jiné</Option>
          </Select>
        </Form.Item>
        {isOtherVariation && (
          <Form.Item
            name="OtherVariation"
            label="Jiná Variace"
            rules={[
              { required: true, message: "Prosím zadejte jinou variaci!" },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="Sizes"
          label="Velikost"
          rules={[{ required: true, message: "Prosím vyber jednu velikost!" }]}
        >
          <Select
            showSearch
            placeholder="Vybrat velikost"
            onChange={handleSizeChange}
          >
            {sizes.map((size) => (
              <Option key={size} value={size}>
                {size}
              </Option>
            ))}
            <Option value="Other">Jiné</Option>
          </Select>
        </Form.Item>
        {isOtherSize && (
          <Form.Item
            name="OtherSize"
            label="Jiná Velikost"
            rules={[
              { required: true, message: "Prosím zadejte jinou velikost!" },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="Season"
          label="Sezóna"
          rules={[{ required: true, message: "Prosím vyberte sezónu!" }]}
        >
          <Select
            showSearch
            placeholder="Vybrat sezónu"
            onChange={handleSeasonChange}
          >
            {seasons.map((season) => (
              <Option key={season} value={season}>
                {season}
              </Option>
            ))}
            <Option value="Other">Jiné</Option>
          </Select>
        </Form.Item>
        {isOtherSeason && (
          <Form.Item
            name="OtherSeason"
            label="Jiná Sezóna"
            rules={[
              { required: true, message: "Prosím zadejte jinou sezónu!" },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="Active"
          label="Aktivní"
          rules={[
            {
              required: true,
              message: "Vyber, zda je produkt aktivní!",
            },
          ]}
        >
          <Select>
            <Option value={true}>Ano</Option>
            <Option value={false}>Ne</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="Description"
          label="Popis"
          rules={[{ required: true, message: "Prosím vlož popis produktu!" }]}
        >
          <Input.TextArea placeholder="Popis produktu" />
        </Form.Item>

        <Form.Item
          name="Preparation"
          label="Postup"
          rules={[
            { required: true, message: "Prosím vypiš přípravu produktu!" },
          ]}
        >
          <Input.TextArea placeholder="Postup přípravy" />
        </Form.Item>

        <Form.Item name="Note" label="Poznámky">
          <Input.TextArea placeholder="Poznámky k receptu" />
        </Form.Item>

        <Form.List name="ingredients">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "ingredient"]}
                    rules={[{ required: true, message: "Chybí ingredience" }]}
                    style={{ minWidth: "250px" }}
                  >
                    <Select
                      showSearch
                      placeholder="Ingredience"
                      onChange={(value, option) => {
                        const selectedIngredient = ingredients.find(
                          (ingredient) => ingredient.id === value
                        );
                        form.setFieldsValue({
                          ingredients: form
                            .getFieldValue("ingredients")
                            .map((ing, index) =>
                              index === name
                                ? {
                                    ...ing,
                                    unit: selectedIngredient.unit,
                                  }
                                : ing
                            ),
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ingredients.map((ingredient) => (
                        <Option key={ingredient.id} value={ingredient.id}>
                          {ingredient.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "quantity"]}
                    rules={[{ required: true, message: "Chybí množství" }]}
                  >
                    <Input placeholder="Množství" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "unit"]}
                    style={{
                      display: "flex",
                      width: "70px",
                    }}
                  >
                    <Input placeholder="mj." disabled />
                  </Form.Item>
                  <Button
                    onClick={() => remove(name)}
                    icon={<MinusOutlined />}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Přidat ingredienci
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Přidat produkt
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewProductModal;
