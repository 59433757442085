import React, { useEffect, useState } from "react";
import { Input, Button, notification, DatePicker } from "antd";
import dayjs from "dayjs";
import { useSocket } from "../../SocketProvider";
import { useAuth } from "oidc-react";
import DeviceControl from "../../components/DeviceControl/DeviceControl";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";

import "./HomeEmployee.scss";
dayjs.locale("cs");

function HomeEmployee() {
  const auth = useAuth();
  const socket = useSocket();

  const [notes, setNotes] = useState();
  const [lastModifiedNotesBy, setLastModifiedNotesBy] = useState("");
  const [lastModifiedNotesDate, setLastModifiedNotesDate] = useState("");
  const [lastModifiedProduct1By, setLastModifiedProduct1By] = useState("");
  const [lastModifiedProduct1Date, setLastModifiedProduct1Date] = useState("");
  const [creamcheeseDate, setCreamcheeseDate] = useState("");
  const [creamcheeseDate2, setCreamcheeseDate2] = useState("");
  const [whippedCreamDate, setWhippedCreamDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotes = () => {
    socket.emit("get_notes");
  };

  const fetchProductManufactureData = () => {
    socket.emit("get_product_manufacture");
  };

  useEffect(() => {
    fetchNotes();
    fetchProductManufactureData();

    socket.on("product_manufacture_data", (data) => {
      if (data && data.length > 0) {
        const first = data[data.length - 1];
        setLastModifiedProduct1By(first.made_by);
        setLastModifiedProduct1Date(
          dayjs(first.added_date).format("DD. MM. v HH:mm")
        );

        const firstDate = dayjs(data[0].manufacture_date).format("YYYY-MM-DD");
        const secondDate = dayjs(data[1].manufacture_date).format("YYYY-MM-DD");
        const thirdDate = dayjs(data[2].manufacture_date).format("YYYY-MM-DD");
        setCreamcheeseDate(firstDate);
        setCreamcheeseDate2(thirdDate);
        setWhippedCreamDate(secondDate);
      }
    });

    socket.on("notes_data", (notesData) => {
      if (notesData.length) {
        setNotes(notesData.map((note) => note.note).join("\n"));
        setLastModifiedNotesBy(
          notesData.map((note) => note.last_modified_by).join("\n")
        );
        const formattedDate = dayjs(
          notesData.map((note) => note.date).join("\n")
        ).format("DD. MM. v HH:mm");
        setLastModifiedNotesDate(formattedDate);
      }
    });

    socket.on("note_update_confirmed", () => {
      openNotification("Vaše poznámka byla úspěšně aktualizována.");
      fetchNotes();
    });
    socket.on("product_manufacture_updated", (response) => {
      if (response.success) {
        fetchProductManufactureData();
        openNotification("Všechny produkty byly úspěšně aktualizovány.");
      } else {
        openNotification("Aktualizace dat produktu selhala.", "error");
      }
    });
    setIsLoading(false);
    return () => {
      socket.off("product_manufacture_data");
      socket.off("notes_data");
      socket.off("note_updated");
      socket.off("note_update_confirmed");
      socket.off("product_manufacture_updated");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const handleNotesChange = (event) => {
    const newNotes = event.target.value;
    setNotes(newNotes);
  };

  const saveNotes = () => {
    const date = new Date(); // Vaše aktuální datum a čas
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    const dataToSend = {
      operation_type: "UPDATE",
      database_type: "mariadb",
      table_name: "Notes",
      data: {
        id: 1,
        note: notes,
        date: formattedDate,
        last_modified_by:
          `${auth.userData?.profile?.given_name} ${auth.userData?.profile?.family_name}` ||
          "undefined user",
      },
      type: "update_note",
    };
    socket.emit("message", JSON.stringify(dataToSend));
  };

  const saveProducts = () => {
    const madeBy = auth.userData?.profile
      ? `${auth.userData.profile.given_name} ${auth.userData.profile.family_name}`
      : "undefined user";

    const products = [
      {
        id: 1,
        product_type: "První Creamcheese",
        manufacture_date: creamcheeseDate,
        made_by: madeBy,
      },
      {
        id: 2,
        product_type: "Šlehačka",
        manufacture_date: whippedCreamDate,
        made_by: madeBy,
      },
      {
        id: 3,
        product_type: "Druhý Creamcheese",
        manufacture_date: creamcheeseDate2,
        made_by: madeBy,
      },
    ];

    // Odeslání všech produktů najednou
    socket.emit("update_product_manufacture", products);

    socket.on("update_error", (data) => {
      if (data.failed) {
        openNotification(
          `Některé produkty nebyly aktualizovány: ${data.failed
            .map((f) => f.id)
            .join(", ")}`
        );
      } else {
        openNotification("Nastala chyba při aktualizaci.");
      }
    });
  };

  const openNotification = (message) => {
    notification.success({
      message: "Aktualizace",
      description: message,
      placement: "bottomRight",
      duration: 2,
    });
  };

  const getExpirySymbol = (date, warningThreshold, dangerThreshold) => {
    const today = dayjs();
    const dateToCheck = dayjs(date);
    const differenceInDays = today.diff(dateToCheck, "day");

    if (differenceInDays >= dangerThreshold) {
      return "⛔";
    } else if (differenceInDays >= warningThreshold) {
      return "⚠️";
    } else return "✅";
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="homeEmployeeContainer">
      <div className="first">
        <div>
          <h3 className="sectionTitle">Poznámky</h3>

          <Input.TextArea
            value={notes}
            onChange={handleNotesChange}
            rows={4}
            autoSize={true}
            placeholder="Zde můžete přidat poznámky..."
          />
        </div>

        <div className="notesButton">
          <Button className="saveButton" onClick={saveNotes}>
            Uložit
          </Button>
        </div>
        <span style={{ fontSize: "12px", paddingTop: "10px" }}>
          Poslední změna: {lastModifiedNotesDate} od {lastModifiedNotesBy}
        </span>
      </div>
      <div className="second">
        {" "}
        <h3 className="sectionTitle">Stav výrobků</h3>
        <div className="container">
          <div className="sectionContainer">
            <h3> Creamcheese #1 {getExpirySymbol(creamcheeseDate, 3, 4)}</h3>
            <div className="inputSection">
              <DatePicker
                locale={locale}
                value={
                  creamcheeseDate ? dayjs(creamcheeseDate, "YYYY-MM-DD") : null
                }
                onChange={(date, dateString) =>
                  setCreamcheeseDate(dateString || "")
                }
              />
            </div>
          </div>
          <div className="sectionContainer">
            <h3> Creamcheese #2 {getExpirySymbol(creamcheeseDate2, 3, 4)}</h3>
            <div className="inputSection">
              <DatePicker
                locale={locale}
                value={
                  creamcheeseDate2
                    ? dayjs(creamcheeseDate2, "YYYY-MM-DD")
                    : null
                }
                onChange={(date, dateString) =>
                  setCreamcheeseDate2(dateString || "")
                }
              />
            </div>
          </div>
          <div className="sectionContainer">
            <h3> Šlehačka {getExpirySymbol(whippedCreamDate, 3, 4)}</h3>
            <div className="inputSection">
              <DatePicker
                locale={locale}
                value={
                  whippedCreamDate
                    ? dayjs(whippedCreamDate, "YYYY-MM-DD")
                    : null
                }
                onChange={(date, dateString) =>
                  setWhippedCreamDate(dateString || "")
                }
              />
            </div>
          </div>
        </div>
        <div className="productButton">
          <Button className="saveButton" onClick={saveProducts}>
            Uložit
          </Button>
        </div>
        <span style={{ fontSize: "12px" }}>
          Poslední aktualizace {lastModifiedProduct1Date}{" "}
          {lastModifiedProduct1By}
        </span>
      </div>

      <div className="third">
        <div className="device">
          <DeviceControl />
        </div>
      </div>
    </div>
  );
}

export default HomeEmployee;
