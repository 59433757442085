import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "oidc-react";
import "./YearBigChartBox.scss";
import callApi from "../../ApiCaller";

const currentMonth = new Date().getMonth() + 1; // Získání aktuálního měsíce (1 až 12)

const YearBigChartBox = () => {
  const [data, setData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const auth = useAuth();
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.employee);
  const isInvestor = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.investor);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const titleStyle = {
    color: isHovered ? "teal" : "white", // Změna barvy při najetí myší
  };

  useEffect(() => {
    // Načtěte data z API pro rok 2021
    callApi("month2021")
      .then((data2021) => {
        // Načtěte data z API pro rok 2022
        callApi("month2022")
          .then((data2022) => {
            // Načtěte data z API pro rok 2023
            callApi("month2023")
              .then((data2023) => {
                // Načtěte data z API pro rok 2024
                callApi("month2024")
                  .then((data2024) => {
                    // Update chartData to include 2024
                    const chartData = Array.from({ length: 12 }, (_, index) => {
                      const month = index + 1;
                      return {
                        name: month.toString(),
                        2021: data2021.month2021[month] || 0,
                        2022: data2022.month2022[month] || 0,
                        2023: data2023.month2023[month] || 0,
                        2024: data2024.month2024[month] || 0,
                      };
                    });
                    setData(chartData);
                  })
                  .catch((error) =>
                    console.error("Error loading 2024 data", error)
                  );
              })
              .catch((error) =>
                console.error("Chyba při načítání dat 2023", error)
              );
          })
          .catch((error) =>
            console.error("Chyba při načítání dat 2022", error)
          );
      })
      .catch((error) => console.error("Chyba při načítání dat 2021", error));
  }, []);

  // Funkce pro zvýraznění aktuálního měsíce
  const renderXAxisTick = (props) => {
    const { x, y, payload } = props;
    const month = parseInt(payload.value, 10);

    // Zvýraznění aktuálního měsíce
    const isCurrentMonth = month === currentMonth;
    const textStyle = {
      fontWeight: isCurrentMonth ? "bold" : "normal",
      fill: isCurrentMonth ? "white" : "#666",
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          style={textStyle}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  // Upravit styl pro osu Y
  const yAxisStyle = {
    fontSize: 12,
  };

  return (
    <div className="bigChartBox">
      <h1 style={{ fontSize: "24px", marginBottom: "5px" }}>Měsíční tržby</h1>

      <div className="chart">
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey="name" tick={renderXAxisTick} />
            <YAxis style={yAxisStyle} />
            <Legend />
            <Tooltip
              content={(props) => {
                const { active, payload, label } = props;

                if (active && payload && payload.length) {
                  const currentDate = new Date();
                  const currentYear = currentDate.getFullYear();
                  const currentDay = currentDate.getDate();
                  let daysInMonth;

                  const formatCurrency = (value) =>
                    `${value.toLocaleString("cs-CZ")} Kč`;
                  const calculateDaysInMonth = (year, month) =>
                    new Date(year, month, 0).getDate();

                  const tooltipData = payload.reduce((acc, curr) => {
                    const year = curr.name.match(/\d+$/)[0]; // Extrakce roku z názvu
                    if (
                      parseInt(label) === currentMonth &&
                      // eslint-disable-next-line eqeqeq
                      year == currentYear
                    ) {
                      daysInMonth = currentDay; // Použití dnešního data pro výpočet
                    } else {
                      daysInMonth = calculateDaysInMonth(year, label); // Výpočet celkového počtu dnů v měsíci
                    }
                    const avgDailyValue = curr.value / daysInMonth;

                    acc[year] = {
                      totalValue: curr.value,
                      avgDailyValue: avgDailyValue,
                    };
                    return acc;
                  }, {});
                  return (
                    <div className="custom-tooltip">
                      <p className="thirdPayload">
                        2021: {formatCurrency(tooltipData["2021"]?.totalValue)}{" "}
                        -{" "}
                        <span>
                          {formatCurrency(
                            tooltipData["2021"]?.avgDailyValue.toFixed(0)
                          )}
                        </span>
                      </p>
                      <p className="secondPayload">
                        2022: {formatCurrency(tooltipData["2022"]?.totalValue)}{" "}
                        -{" "}
                        <span>
                          {formatCurrency(
                            tooltipData["2022"]?.avgDailyValue.toFixed(0)
                          )}
                        </span>
                      </p>
                      <p className="firstPayload">
                        2023: {formatCurrency(tooltipData["2023"]?.totalValue)}{" "}
                        -{" "}
                        <span>
                          {formatCurrency(
                            tooltipData["2023"]?.avgDailyValue.toFixed(0)
                          )}
                        </span>
                      </p>
                      <p className="fourthPayload">
                        2024: {formatCurrency(tooltipData["2024"]?.totalValue)}{" "}
                        -{" "}
                        <span>
                          {formatCurrency(
                            tooltipData["2024"]?.avgDailyValue.toFixed(0)
                          )}
                        </span>
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />

            <Area
              type="monotone"
              dataKey="2021"
              stroke="#8884d8"
              fill="transparent"
              name="Rok 2021"
            />
            <Area
              type="monotone"
              dataKey="2022"
              stroke="#ffc658"
              fill="transparent"
              name="Rok 2022"
            />
            <Area
              type="monotone"
              dataKey="2023"
              stroke="#82ca9d"
              fill="transparent"
              name="Rok 2023"
            />
            <Area
              type="monotone"
              dataKey="2024"
              stroke="#70a1ff"
              fill="transparent"
              name="Rok 2024"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default YearBigChartBox;
