import React, { useState, useEffect } from "react";
import { Table, DatePicker, Input } from "antd";
import { useSocket } from "../../SocketProvider";
import DateRangeSelector from "../../utils/DateRangeSelector";
import dayjs from "dayjs";
import "./salesByProducts.scss";
import "dayjs/locale/cs";

const { RangePicker } = DatePicker;

const SalesByProducts = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const socket = useSocket();

  const getYearStartEnd = () => {
    const currentYear = new Date().getFullYear();
    const startOfYear = `${currentYear}-01-01`;
    const endOfYear = `${currentYear}-12-31`;
    return [startOfYear, endOfYear];
  };

  const fetchSalesData = (startDate, endDate, search) => {
    socket.emit("fetch-product-sales", { startDate, endDate });

    socket.on("product-sales-data", (rows) => {
      const dataWithKeys = rows.map((row, index) => ({
        ...row,
        key: index,
      }));

      const filteredData = dataWithKeys.filter((item) =>
        item.Product.toLowerCase().includes(search.toLowerCase())
      );

      setData(filteredData);
    });

    socket.on("product-sales-error", (error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    const [startOfYear, endOfYear] = getYearStartEnd();
    fetchSalesData(startOfYear, endOfYear, searchText);

    return () => {
      socket.off("fetch-product-sales");
      socket.off("product-sales-data");
      socket.off("product-sales-error");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (dates) => {
    if (!dates || dates.length !== 2) {
      console.error("Invalid date range provided:", dates);
      return;
    }

    // Convert dayjs objects to strings in the expected format.
    const dateStrings = dates.map((date) => date.format("YYYY-MM-DD"));
    setSelectedDate(dates); // Store the dayjs objects for any further use.
    fetchSalesData(dateStrings[0], dateStrings[1], searchText);
  };

  const handleSearchChange = (e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);

    // Zkontrolujte, jestli je 'selectedDate' nastaveno a převeďte na stringy ve správném formátu
    const dates = selectedDate
      ? selectedDate.map((date) => date.format("YYYY-MM-DD"))
      : getYearStartEnd();
    fetchSalesData(dates[0], dates[1], newSearchText);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: "CZK",
    }).format(value);
  };

  const categories = [...new Set(data.map((item) => item.Category))];
  const units = [...new Set(data.map((item) => item.Unit))];

  const columns = [
    {
      title: "Produkt",
      dataIndex: "Product",
      key: "Product",
    },
    {
      title: "Kategorie",
      dataIndex: "Category",
      key: "Category",
      filters: categories.map((category) => ({
        text: category,
        value: category,
      })),
      onFilter: (value, record) => record.Category === value,
    },
    {
      title: "Jednotka",
      dataIndex: "Unit",
      key: "Unit",
      filters: units.map((unit) => ({ text: unit, value: unit })),
      onFilter: (value, record) => record.Unit === value,
    },
    {
      title: "Celkové množství",
      dataIndex: "TotalQuantity",
      key: "TotalQuantity",
      sorter: (a, b) => a.TotalQuantity - b.TotalQuantity,
    },
    {
      title: "Celkový prodej",
      dataIndex: "TotalAdjusted",
      key: "TotalAdjusted",
      sorter: (a, b) => a.TotalAdjusted - b.TotalAdjusted,
      render: (text) => formatCurrency(text), // Přidáno zde
    },
  ];

  const getTotalSalesSum = () => {
    const total = data.reduce((sum, record) => sum + record.TotalAdjusted, 0);
    return formatCurrency(total);
  };

  return (
    <div className="salesByProducts">
      <h1 className="salesByProductsH1">Prodeje dle produktů</h1>
      <div className="salesByProductsHeader">
        <div className="inputwithrangepicker">
          <div className="rangePicker">
            <RangePicker
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              defaultValue={getYearStartEnd().map(dayjs)}
            />
          </div>
          <div className="input">
            <Input
              placeholder="Hledat produkt"
              value={searchText}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div className="datePickers">
          <DateRangeSelector onDateChange={handleDateChange} />
        </div>
      </div>
      <div className="table">
        <Table
          dataSource={data}
          columns={columns}
          footer={() => (
            <div>
              <strong>Total Sales: </strong>
              {getTotalSalesSum()}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default SalesByProducts;
