import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  message,
  Select,
  InputNumber,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import callApi from "../../ApiCaller";
import ImageUpload from "../../components/ImagePicker/ImageUpload";

const { Option } = Select;

const EditIntermediateProductRecipe = ({
  visible,
  onCancel,
  onUpdate,
  onDelete,
  recipe,
  supplies,
  intermediateProducts,
}) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [types, setTypes] = useState([]);
  const [addingNewCategory, setAddingNewCategory] = useState(false);
  const [addingNewUnit, setAddingNewUnit] = useState(false);
  const [addingNewType, setAddingNewType] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi("intermediate-products", "GET");

        if (response.error) {
          message.error("Chyba při načítání dat");
        } else {
          const uniqueCategories = [
            ...new Set(response.map((product) => product.Category)),
          ];
          const uniqueUnits = [
            ...new Set(response.map((product) => product.Unit)),
          ];
          const uniqueTypes = [
            ...new Set(response.map((product) => product.Type)),
          ];
          setCategories(uniqueCategories);
          setUnits(uniqueUnits);
          setTypes(uniqueTypes);
        }
      } catch (error) {
        message.error("Chyba při načítání dat");
      }
    };

    fetchData();

    if (recipe && visible) {
      const selectedProduct = intermediateProducts.find(
        (product) =>
          product.IntermediateProductID === recipe.IntermediateProductID
      );

      form.setFieldsValue({
        name: selectedProduct?.Name || "",
        category: selectedProduct?.Category || "",
        unit: selectedProduct?.Unit || "",
        type: selectedProduct?.Type || "",
        description: recipe.Description || "",
        preparation: recipe.Preparation || "",
        note: recipe.Note || "",
        ingredients:
          recipe.IntermediateProductRecipeIngredients?.map((ingredient) => ({
            supplyId: ingredient.SupplyID,
            quantity: ingredient.Quantity,
            unit: `${ingredient.Supply?.Unit1 || ""} (${
              ingredient.Supply?.Unit2 || ""
            })`,
          })) || [],
      });
    }
  }, [recipe, form, intermediateProducts, visible]);

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      image: uploadedImage,
    };

    try {
      const response = await callApi(
        `intermediate-products/recipes/${recipe.IntermediateProductRecipeID}`,
        "PUT",
        data
      );
      if (response.error) {
        message.error("Chyba při aktualizaci receptu");
      } else {
        message.success("Recept úspěšně aktualizován");
        onUpdate(response);
        form.resetFields();
        onCancel();
      }
    } catch (error) {
      message.error("Chyba při aktualizaci receptu");
    }
  };

  const handleDelete = async () => {
    const productId = recipe.IntermediateProductID;
    await onDelete(productId);
  };

  const handleSupplyChange = (value, name) => {
    const selectedSupply = supplies.find((supply) => supply.SupplyID === value);
    form.setFieldsValue({
      ingredients: form.getFieldValue("ingredients").map((ing, index) =>
        index === name
          ? {
              ...ing,
              unit: `${selectedSupply.Unit1} (${selectedSupply.Unit2})`,
            }
          : ing
      ),
    });
  };

  return (
    <Modal
      title="Upravit recept"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Název meziproduktu"
          rules={[
            { required: true, message: "Prosím vložte název meziproduktu!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Kategorie"
          rules={[
            {
              required: true,
              message: "Prosím vložte kategorii meziproduktu!",
            },
          ]}
        >
          {addingNewCategory ? (
            <Input
              placeholder="Nová kategorie"
              onBlur={() => setAddingNewCategory(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte kategorii"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewCategory(true);
                  form.setFieldsValue({ category: "" });
                }
              }}
            >
              {categories.map((category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="unit"
          label="Měrná jednotka"
          rules={[
            { required: true, message: "Prosím vložte měrnou jednotku!" },
          ]}
        >
          {addingNewUnit ? (
            <Input
              placeholder="Nová měrná jednotka"
              onBlur={() => setAddingNewUnit(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte měrnou jednotku"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewUnit(true);
                  form.setFieldsValue({ unit: "" });
                }
              }}
            >
              {units.map((unit) => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="type"
          label="Typ"
          rules={[
            { required: true, message: "Prosím vložte typ meziproduktu!" },
          ]}
        >
          {addingNewType ? (
            <Input
              placeholder="Nový typ"
              onBlur={() => setAddingNewType(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte typ"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewType(true);
                  form.setFieldsValue({ type: "" });
                }
              }}
            >
              {types.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="description"
          label="Popis"
          rules={[{ required: true, message: "Prosím vložte popis!" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="preparation"
          label="Příprava"
          rules={[{ required: true, message: "Prosím vložte přípravu!" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="note" label="Poznámka">
          <Input.TextArea rows={2} />
        </Form.Item>

        <Form.Item name="image" label="Nahrát obrázek">
          <ImageUpload
            identifier={recipe?.IntermediateProductID}
            identifierType="intermediateProductID"
            onUpdate={(imageId) => setUploadedImage(imageId)}
          />
        </Form.Item>

        <Form.List name="ingredients">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "supplyId"]}
                    rules={[
                      { required: true, message: "Prosím vyberte surovinu!" },
                    ]}
                    style={{ minWidth: "250px" }}
                  >
                    <Select
                      showSearch
                      placeholder="Vyberte produkt nebo ingredienci"
                      onChange={(value) => handleSupplyChange(value, name)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {supplies.map((supply) => (
                        <Option key={supply.SupplyID} value={supply.SupplyID}>
                          {supply.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "quantity"]}
                    rules={[
                      { required: true, message: "Prosím vložte množství!" },
                    ]}
                  >
                    <InputNumber placeholder="Množství" min={0} step={0.01} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "unit"]}
                    style={{
                      display: "flex",
                      width: "70px",
                    }}
                  >
                    <Input placeholder="mj." disabled />
                  </Form.Item>
                  <MinusOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Přidat produkt nebo ingredienci
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Uložit
            </Button>
            <Button type="danger" onClick={handleDelete}>
              Smazat
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditIntermediateProductRecipe;
