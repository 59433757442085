import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "oidc-react";

function Login() {
    const auth = useAuth();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (auth.userData && !auth.isLoading) {
        navigate("/home");
      }
    }, [auth, navigate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <img src="/logo.png" alt="" style={{ width: "250px" }} />
      <span
        style={{
          color: "black",
          padding: "25px",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        Loading...
      </span>
    </div>
  );
}

export default Login;
