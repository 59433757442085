import React, { useState, useEffect } from "react";
import { Form, Table, Input, Button, DatePicker, Drawer } from "antd";
import "jspdf-autotable";
import "./products.scss";
import callApi from "../../ApiCaller";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const [form] = Form.useForm();
  const uniqueProductGroups = [
    ...new Set(products.map((product) => product.ProductGroupId)),
  ];
  console.log(products);

  // Načtení dat z vašeho API
  useEffect(() => {
    callApi("products")
      .then((data) => {
        setProducts(data);
        setFilteredProducts(data);
      })
      .catch((error) => {
        console.error("Error loading products:", error);
      });
  }, []);

  const handleResetSearch = () => {
    setFilteredProducts(products);
    setSearchValue("");
  };
  const handleSearch = () => {
    const searchData = products.filter((product) => {
      return Object.values(product).some((value) =>
        value
          ? value.toString().toLowerCase().includes(searchValue.toLowerCase())
          : false
      );
    });
    setFilteredProducts(searchData);
  };

  const taxFilters = [...new Set(products.map((item) => item.Tax))]
    .filter((tax) => tax !== null && tax !== undefined)
    .sort()
    .map((tax) => ({ text: tax, value: tax }));

  const columns =
    products.length > 0
      ? [
          {
            title: "Id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "Obrázek",
            dataIndex: "Image",
            render: (text, record) => {
              const imageStyle = {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70px",
                width: "70px",
              };
              if (record.Image) {
                const blob = new Blob([
                  new Uint8Array(record.Image.data).buffer,
                ]);
                const imageUrl = URL.createObjectURL(blob);
                return (
                  <div style={imageStyle}>
                    <img
                      src={imageUrl}
                      alt=""
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </div>
                );
              } else {
                return (
                  <img
                    src=""
                    alt=""
                    style={{ minWidth: "70px", minHeight: "70px" }}
                  />
                );
              }
            },
          },
          {
            title: "Produkt",
            dataIndex: "Name",
            sorter: (a, b) => (a.Name || "").localeCompare(b.Name || ""),
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Kategorie",
            dataIndex: "ProductGroupId",
            sorter: (a, b) => {
              const groupA = (a.ProductGroupId || "").toString();
              const groupB = (b.ProductGroupId || "").toString();
              return groupA.localeCompare(groupB);
            },
            sortDirections: ["ascend", "descend", "ascend"],
            filters: uniqueProductGroups
              .filter((productGroup) => productGroup !== null)
              .sort((a, b) => a.localeCompare(b))
              .map((productGroup) => ({
                text: productGroup,
                value: productGroup,
              })),
            onFilter: (value, record) => {
              const recordGroup = (record.ProductGroupId || "").toString();
              return recordGroup === value;
            },
          },
          {
            title: "Cena bez DPH",
            dataIndex: "Price",
            key: "PriceWithoutTax",
            render: (text, record) => {
              // Zkontroluje, zda existuje hodnota daně a je definovaná
              const taxValue = record.Tax ? record.Tax.replace("%", "") : "0";
              // Převede řetězec na číslo
              const taxNumber = parseFloat(taxValue);
              // Vypočítá cenu bez daně
              const priceWithoutTax = record.Price / (1 + taxNumber / 100);
              // Zobrazí cenu formátovanou na 2 desetinná místa
              return `${priceWithoutTax.toFixed(2)} Kč`;
            },
            sorter: (a, b) => {
              const taxValueA = a.Tax ? parseFloat(a.Tax.replace("%", "")) : 0;
              const taxValueB = b.Tax ? parseFloat(b.Tax.replace("%", "")) : 0;
              const priceWithoutTaxA = a.Price / (1 + taxValueA / 100);
              const priceWithoutTaxB = b.Price / (1 + taxValueB / 100);
              return priceWithoutTaxA - priceWithoutTaxB;
            },
            sortDirections: ["descend", "ascend"],
          },
          {
            title: "DPH",
            dataIndex: "Tax",
            filters: taxFilters, // Předpokládá se, že taxFilters je již definován
            onFilter: (value, record) => record.Tax === value,
            sorter: (a, b) => {
              // Kontrola, zda je Tax null nebo ne a pak odstranění procenta a převedení na číslo
              let taxA = a.Tax ? parseFloat(a.Tax.replace("%", "")) : 0;
              let taxB = b.Tax ? parseFloat(b.Tax.replace("%", "")) : 0;
              return taxA - taxB;
            },
            sortDirections: ["descend", "ascend"],
          },
          {
            title: "Cena vč. DPH",
            dataIndex: "Price",
            render: (text) => `${text} Kč`,
            sorter: (a, b) => a.Price - b.Price,
            sortDirections: ["ascend", "descend", "ascend"],
          },
          {
            title: "Povoleno?",
            dataIndex: "IsEnabled",
            render: (text) => {
              return (
                <div className={`icon-cell ${text ? "enabled" : "disabled"}`}>
                  {text ? "✔" : "❌"}
                </div>
              );
            },
            filters: [
              { text: "✔", value: 1 },
              { text: "❌", value: 0 },
            ],
            onFilter: (value, record) => record.IsEnabled === value,
            sorter: (a, b) => a.IsEnabled - b.IsEnabled,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "Vytvořeno",
            dataIndex: "DateCreated",
            render: (text) => {
              return text ? new Date(text).toLocaleString() : "";
            },
            sorter: (a, b) => new Date(a.DateCreated) - new Date(b.DateCreated),
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "Upraveno",
            dataIndex: "DateUpdated",
            render: (text) => {
              return text ? new Date(text).toLocaleString() : "";
            },
            sorter: (a, b) => new Date(a.DateUpdated) - new Date(b.DateUpdated),
            sortDirections: ["ascend", "descend"],
          },
        ]
      : [];

  const formItems = columns
    .filter(
      (column) => column.dataIndex !== "ID" && column.dataIndex !== "Image"
    )
    .map((column) => {
      return (
        <Form.Item
          key={column.dataIndex}
          label={column.title}
          name={column.dataIndex}
        >
          <Input placeholder={column.title} />
        </Form.Item>
      );
    });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    // Implementujte filtrování produktů podle vybraného data.
    if (dates && dates.length === 2) {
      const startDate = new Date(dates[0]);
      startDate.setHours(0, 0, 0, 0); // Nastavit začátek dne
      const endDate = new Date(dates[1]);
      endDate.setHours(23, 59, 59, 999); // Nastavit konec dne

      const filteredData = products.filter((product) => {
        const createdDate = new Date(product.DateCreated);
        return createdDate >= startDate && createdDate <= endDate;
      });

      setFilteredProducts(filteredData);
    } else {
      setFilteredProducts(products);
    }
  };
  const data = filteredProducts.map((product) => ({
    ...product,
    key: product.id,
    Tax: product.Tax,
  }));

  return (
    <div>
      <h1>Produkty</h1>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 10,
        }}
      >
        <Form.Item>
          <Search
            placeholder="Hledat"
            onSearch={handleSearch}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ marginBottom: "5px", maxWidth: "250px" }}
            value={searchValue}
          />
          <Button onClick={handleResetSearch}>Reset</Button>
        </Form.Item>

        <Form.Item>
          <RangePicker
            locale={locale}
            placeholder={["Začátek", "Konec"]}
            onChange={handleDateRangeChange}
            value={dateRange}
          />
        </Form.Item>
      </Form>
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        pagination={{ className: "custom-pagination" }}
        footer={() => (
          <div>
            <span>Počet zobrazených produktů: {filteredProducts.length}</span>
          </div>
        )}
      />

      <Drawer
        title="Přidat nový produkt"
        open={isModalVisible}
        onClose={handleCancel}
        width={400} // Zde můžete nastavit šířku podle svých potřeb
      >
        <Form form={form}>{formItems}</Form>
      </Drawer>
    </div>
  );
};

export default Products;
