import React from "react";
import "./receipt.scss";

const Receipt = ({
  receipt_id,
  store_name,
  store_address,
  store_ico,
  store_dic,
  store_phone,
  cashier_name,
  receipt_date,
  receipt_time,
  payment_method,
  transaction_number,
  items = [],
  vat_summary = [],
  total_net,
  total_vat,
  total_gross,
  rounding,
  amount_paid,
  change_given,
  fiscal_code,
  store_website,
  store_contact,
  additional_info,
  receipt_footer,
}) => {
  return (
    <div className="receipt-template">
      <div className="receipt-header">
        <h1>{store_name}</h1>
        <p>{store_address}</p>
        {store_ico && <p>IČO: {store_ico}</p>}
        {store_dic && <p>DIČ: {store_dic}</p>}
        <p>Telefon: {store_phone}</p>
        {cashier_name && (
          <p>
            <strong>Pokladník:</strong> {cashier_name}
          </p>
        )}
        {receipt_date && receipt_time && (
          <p>
            <strong>Datum:</strong> {receipt_date} <strong>Čas:</strong>{" "}
            {receipt_time}
          </p>
        )}
        {transaction_number && (
          <p>
            <strong>Číslo transakce:</strong> {transaction_number}
          </p>
        )}
        {receipt_id && (
          <p>
            <strong>Číslo účtenky:</strong> {receipt_id}
          </p>
        )}
      </div>

      <div className="receipt-items">
        <table>
          <thead>
            <tr>
              <th>Popis</th>
              <th>Množství</th>
              <th>Jednotková cena</th>
              <th>Celkem</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.description}</td>
                <td>{item.quantity}</td>
                <td>{item.unit_price} Kč</td>
                <td>{item.total_price} Kč</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="receipt-vat-summary">
        <table>
          <thead>
            <tr>
              <th>Základ DPH</th>
              <th>Sazba DPH (%)</th>
              <th>DPH</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(vat_summary) && vat_summary.length > 0 ? (
              vat_summary.map((vat, index) => (
                <tr key={index}>
                  <td>{vat.vat_base} Kč</td>
                  <td>{vat.vat_rate}</td>
                  <td>{vat.vat_amount} Kč</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">Žádné údaje o DPH</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="receipt-summary">
        <table>
          <tbody>
            <tr>
              <td>Mezisoučet:</td>
              <td>{total_net} Kč</td>
            </tr>
            <tr>
              <td>DPH:</td>
              <td>{total_vat} Kč</td>
            </tr>
            <tr>
              <td>Zaokrouhlení:</td>
              <td>{rounding} Kč</td>
            </tr>
            <tr>
              <td>
                <strong>Celkem:</strong>
              </td>
              <td className="total-amount">
                <strong>{total_gross} Kč</strong>
              </td>
            </tr>
            <tr>
              <td>Zaplaceno:</td>
              <td>{amount_paid} Kč</td>
            </tr>
            <tr>
              <td>Vydáno zpět:</td>
              <td>{change_given} Kč</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="receipt-footer">
        <p>
          <strong>Způsob platby:</strong> {payment_method}
        </p>
        {fiscal_code && (
          <p>
            <strong>Fiskální kód:</strong> {fiscal_code}
          </p>
        )}
        {store_website && (
          <p>
            <strong>Webová stránka:</strong>{" "}
            <a href={store_website}>{store_website}</a>
          </p>
        )}
        {store_contact && <p>{store_contact}</p>}
        {additional_info && (
          <div className="additional-info">
            <h4>Další informace:</h4>
            <pre>{JSON.stringify(additional_info, null, 2)}</pre>
          </div>
        )}
        {receipt_footer && <p>{receipt_footer}</p>}
      </div>
    </div>
  );
};

export default Receipt;
