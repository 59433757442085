import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  message,
  Select,
  InputNumber,
  Alert,
} from "antd";
import callApi from "../../ApiCaller";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddIntermediateProductRecipe = ({ visible, onCancel, onAdd }) => {
  const [supplies, setSupplies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [types, setTypes] = useState([]);
  const [addingNewCategory, setAddingNewCategory] = useState(false);
  const [addingNewUnit, setAddingNewUnit] = useState(false);
  const [addingNewType, setAddingNewType] = useState(false);
  const [form] = Form.useForm();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (visible) {
      const fetchData = async () => {
        try {
          const suppliesResponse = await callApi("supplies");
          const productsResponse = await callApi("intermediate-products");
          if (suppliesResponse.error) {
            setError(suppliesResponse.message);
          } else {
            setSupplies(suppliesResponse);
          }

          if (productsResponse.error) {
            setError(productsResponse.message);
          } else {
            const uniqueCategories = [
              ...new Set(productsResponse.map((product) => product.Category)),
            ];
            const uniqueUnits = [
              ...new Set(productsResponse.map((product) => product.Unit)),
            ];
            const uniqueTypes = [
              ...new Set(productsResponse.map((product) => product.Type)),
            ];
            setCategories(uniqueCategories);
            setUnits(uniqueUnits);
            setTypes(uniqueTypes);
          }
        } catch (error) {
          setError(error.message);
        }
      };
      fetchData();
    }
  }, [visible]);

  const handleSubmit = async (values) => {
    try {
      const response = await callApi("intermediate-products", "POST", values);

      if (response.error) {
        setError(response.message);
        message.error("Chyba při přidávání meziproduktu a receptu");
        return;
      }

      message.success("Meziprodukt a recept úspěšně přidány");
      onAdd(response);
      form.resetFields();
      onCancel();
    } catch (error) {
      setError(error.message);
      message.error("Chyba při přidávání meziproduktu a receptu");
    }
  };

  if (error) {
    return <Alert message={`Error: ${error}`} type="error" />;
  }

  return (
    <Modal
      title="Přidat nový recept"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Název meziproduktu"
          rules={[
            { required: true, message: "Prosím vložte název meziproduktu!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Kategorie"
          rules={[
            {
              required: true,
              message: "Prosím vložte kategorii meziproduktu!",
            },
          ]}
        >
          {addingNewCategory ? (
            <Input
              placeholder="Nová kategorie"
              onBlur={() => setAddingNewCategory(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte kategorii"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewCategory(true);
                  form.setFieldsValue({ category: "" });
                }
              }}
            >
              {categories.map((category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="unit"
          label="Měrná jednotka"
          rules={[
            { required: true, message: "Prosím vložte měrnou jednotku!" },
          ]}
        >
          {addingNewUnit ? (
            <Input
              placeholder="Nová měrná jednotka"
              onBlur={() => setAddingNewUnit(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte měrnou jednotku"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewUnit(true);
                  form.setFieldsValue({ unit: "" });
                }
              }}
            >
              {units.map((unit) => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="type"
          label="Typ"
          rules={[
            { required: true, message: "Prosím vložte typ meziproduktu!" },
          ]}
        >
          {addingNewType ? (
            <Input
              placeholder="Nový typ"
              onBlur={() => setAddingNewType(false)}
            />
          ) : (
            <Select
              placeholder="Vyberte typ"
              onChange={(value) => {
                if (value === "other") {
                  setAddingNewType(true);
                  form.setFieldsValue({ type: "" });
                }
              }}
            >
              {types.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
              <Option value="other">Jiné</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name="description"
          label="Popis"
          rules={[{ required: true, message: "Prosím vložte popis!" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="preparation"
          label="Příprava"
          rules={[{ required: true, message: "Prosím vložte přípravu!" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="note" label="Poznámka">
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.List name="ingredients">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "supplyId"]}
                    rules={[
                      { required: true, message: "Prosím vyberte surovinu!" },
                    ]}
                    style={{ minWidth: "250px" }}
                  >
                    <Select
                      showSearch
                      placeholder="Vyberte surovinu"
                      onChange={(value) => {
                        const selectedSupply = supplies.find(
                          (supply) => supply.SupplyID === value
                        );
                        form.setFieldsValue({
                          ingredients: form
                            .getFieldValue("ingredients")
                            .map((ing, index) =>
                              index === name
                                ? { ...ing, unit: selectedSupply.Unit1 }
                                : ing
                            ),
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {supplies.map((supply) => (
                        <Option key={supply.SupplyID} value={supply.SupplyID}>
                          {supply.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "quantity"]}
                    rules={[
                      { required: true, message: "Prosím vložte množství!" },
                    ]}
                  >
                    <InputNumber placeholder="Množství" min={0} step={0.01} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "unit"]}
                    style={{
                      display: "flex",
                      width: "70px",
                    }}
                  >
                    <Input placeholder="mj." disabled />
                  </Form.Item>
                  <Button
                    onClick={() => remove(name)}
                    icon={<MinusOutlined />}
                  />{" "}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Přidat surovinu
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Přidat
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddIntermediateProductRecipe;
