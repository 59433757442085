import React, { useState } from "react";
import ULuzickehoSeminare from "./ULuzickehoSeminare";
import DalsiPobocka from "./DalsiPobocka";

import { useAuth } from "oidc-react";

const ShiftSheduler = () => {
  const [firstCalendarVisible, setFirstCalendarVisible] = useState(true);
  const [secondCalendarVisible, setSecondCalendarVisible] = useState(false);
  const auth = useAuth();

  const toggleFirstCalendarVisibility = () => {
    setFirstCalendarVisible((prevVisibility) => !prevVisibility);
  };
  const toggleSecondCalendarVisibility = () => {
    setSecondCalendarVisible((prevVisibility) => !prevVisibility);
  };

  return (
    <div>
      <h1 style={{ marginBottom: "10px" }}>Směny</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h2
          style={{ cursor: "pointer", userSelect: "none" }}
          onClick={toggleFirstCalendarVisibility}
        >
          U lužického semináře
        </h2>
      </div>

      <ULuzickehoSeminare auth={auth} isVisible={firstCalendarVisible} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <h2
          style={{ cursor: "pointer", userSelect: "none" }}
          onClick={toggleSecondCalendarVisibility}
        >
          Další Pobočka
        </h2>
      </div>
      <DalsiPobocka auth={auth} isVisible={secondCalendarVisible} />
    </div>
  );
};

export default ShiftSheduler;
