import { useState, useEffect } from "react";
import "./monthTopCustomerBox.scss";
import { useSocket } from "../../SocketProvider";

const MonthTopCustomerBox = () => {
  const [topCustomers, setTopCustomers] = useState([]);
  const socket = useSocket();

  const fetchMonthlyTopCustomers = () => {
    const query = `
    SELECT c.Name, c.Email, d.CustomerId, SUM(d.Total) AS TotalPurchases
    FROM Document AS d
    INNER JOIN Customer AS c ON d.CustomerId = c.Id
    WHERE strftime('%m', d.DateCreated) = strftime('%m', 'now') AND strftime('%Y', d.DateCreated) = strftime('%Y', 'now')
    AND c.Id <> 1
    GROUP BY d.CustomerId
    ORDER BY TotalPurchases DESC
    LIMIT 5 
    `;
    socket.emit("fetch-top-monthly-customers", { query });
  };

  useEffect(() => {
    fetchMonthlyTopCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("top-monthly-customers", (data) => {
      setTopCustomers(data);
    });

    return () => {
      socket.off("top-monthly-customers"); // Unsubscribe when component unmounts
    };
  }, [socket]);

  // Funkce pro získání URL ikony na základě jména zákazníka
  function getIconUrl(name) {
    const lowercaseName = name.toLowerCase();
    const iconWords = [
      "aau",
      "lootea",
      "švejk",
      "bagel",
      "follow bubble",
      "jelínek",
      "alebrijes",
    ];

    for (const word of iconWords) {
      if (lowercaseName.includes(word)) {
        return `/customers_icons/${word}.png`;
      }
    }

    // Pokud se žádná shoda nenajde, použijte výchozí ikonu noavatar.png
    return "/noavatar.png";
  }

  return (
    <div className="topBox">
      <h1>Nejlepší zákazník tohoto měsíce</h1>
      <div className="list">
        {topCustomers.map((user) => (
          <div className="listItem" key={user.CustomerId}>
            <div className="user">
              <img src={getIconUrl(user.Name)} alt={user.Name} />
              <div className="userTexts">
                <span className="username">{user.Name}</span>
              </div>
            </div>
            <span className="amount">{Math.round(user.TotalPurchases)} Kč</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthTopCustomerBox;
