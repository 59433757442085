import React from "react";
import "./invoice.scss";

const formatPrice = (value) => {
  return value !== null && value !== undefined && value !== 0
    ? Number(value).toFixed(2)
    : value;
};

const Invoice = ({
  invoice_number,
  supplier_name,
  supplier_street_address,
  supplier_city,
  supplier_postal_code,
  supplier_country,
  supplier_tax_id,
  supplier_vat_id,
  supplier_phone,
  supplier_email,
  supplier_bank_account = {},
  invoice_date,
  due_date,
  taxable_date,
  payment_method,
  variable_symbol,
  constant_symbol,
  order_number,
  items = [],
  total_net,
  total_vat,
  total_gross,
  prepaid_amount,
  total_after_prepayment,
  amount_due,
  rounding = 0,
  evidence_number,
  specific_symbol,
  iban,
  bic_swift,
  payment_status,
  paid_amount,
  warranty_period,
  delivery_date,
  website,
  contact,
  sale_number,
  delivery_address,
  payment_terms,
  additional_info,
  vat_summary = {},
}) => {
  return (
    <div className="invoice-template">
      <div className="invoice-header">
        <h1>FAKTURA</h1>
        <div className="invoice-info">
          {invoice_number && (
            <p>
              <strong>Faktura:</strong> {invoice_number}
            </p>
          )}
          {invoice_date && (
            <p>
              <strong>Datum:</strong> {invoice_date}
            </p>
          )}
          {due_date && (
            <p>
              <strong>Datum splatnosti:</strong> {due_date}
            </p>
          )}
          {taxable_date && (
            <p>
              <strong>Datum zdanitelného plnění:</strong> {taxable_date}
            </p>
          )}
        </div>
      </div>

      <div className="invoice-addresses">
        <div className="supplier-info">
          <h3>
            Dodavatel: <span>{supplier_name}</span>
          </h3>
          <p>{supplier_street_address}</p>
          <p>{supplier_city}</p>
          <p>{supplier_postal_code}</p>
          <p>{supplier_country}</p>
          {supplier_tax_id && (
            <p>
              <strong>IČO:</strong> {supplier_tax_id}
            </p>
          )}
          {supplier_vat_id && (
            <p>
              <strong>DIČ:</strong> {supplier_vat_id}
            </p>
          )}
          {supplier_phone && (
            <p>
              <strong>Telefon:</strong> {supplier_phone}
            </p>
          )}
          {supplier_email && (
            <p>
              <strong>Email:</strong> {supplier_email}
            </p>
          )}
          {website && (
            <p>
              <strong>Webová stránka:</strong> {website}
            </p>
          )}

          {supplier_bank_account && (
            <div>
              <strong>Bankovní účty:</strong>
              {typeof supplier_bank_account === "object" ? (
                <ul>
                  {Object.entries(supplier_bank_account).map(
                    ([bankName, accountNumber]) => (
                      <li key={bankName}>
                        {bankName}: {accountNumber}
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <p>{supplier_bank_account}</p>
              )}
            </div>
          )}
        </div>
        <div className="invoice-details">
          <div className="details-column">
            <table>
              <tbody>
                {payment_method && (
                  <tr>
                    <td>Způsob platby:</td>
                    <td>{payment_method}</td>
                  </tr>
                )}
                {variable_symbol && (
                  <tr>
                    <td>Variabilní symbol:</td>
                    <td>{variable_symbol}</td>
                  </tr>
                )}
                {constant_symbol && (
                  <tr>
                    <td>Konstantní symbol:</td>
                    <td>{constant_symbol}</td>
                  </tr>
                )}
                {specific_symbol && (
                  <tr>
                    <td>Specifický symbol:</td>
                    <td>{specific_symbol}</td>
                  </tr>
                )}
                {order_number && (
                  <tr>
                    <td>Číslo objednávky:</td>
                    <td>{order_number}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="details-column">
            <table>
              <tbody>
                {sale_number && (
                  <tr>
                    <td>Číslo prodeje:</td>
                    <td>{sale_number}</td>
                  </tr>
                )}
                {delivery_date && (
                  <tr>
                    <td>Datum dodání:</td>
                    <td>{delivery_date}</td>
                  </tr>
                )}
                {delivery_address && (
                  <tr>
                    <td>Dodací adresa:</td>
                    <td>{delivery_address}</td>
                  </tr>
                )}
                {payment_terms && (
                  <tr>
                    <td>Platební podmínky:</td>
                    <td>{payment_terms}</td>
                  </tr>
                )}
                {evidence_number && (
                  <tr>
                    <td>Číslo dokladu:</td>
                    <td>{evidence_number}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="invoice-items">
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Popis</th>
              <th style={{ textAlign: "center" }}>Množství</th>
              <th style={{ textAlign: "right" }}>Cena ks</th>
              {items.some(
                (item) => item.vat_rate !== null && item.vat_rate !== undefined
              ) && <th style={{ textAlign: "right" }}>DPH%</th>}
              {items.some(
                (item) =>
                  item.vat?.vat_base !== null &&
                  item.vat?.vat_base !== undefined
              ) && <th style={{ textAlign: "right" }}>bez DPH</th>}
              {items.some(
                (item) =>
                  item.vat?.vat_amount !== null &&
                  item.vat?.vat_amount !== undefined
              ) && <th style={{ textAlign: "right" }}>DPH</th>}
              <th style={{ textAlign: "right" }}>Celkem bez DPH</th>
              <th style={{ textAlign: "right" }}>Celkem s DPH</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.description}</td>
                <td style={{ textAlign: "center" }}>
                  {item.quantity !== null && item.quantity !== undefined
                    ? item.quantity
                    : 1}
                </td>
                <td style={{ textAlign: "right" }}>
                  {" "}
                  {formatPrice(item.unit_price)}
                </td>
                {item.vat_rate !== null && item.vat_rate !== undefined && (
                  <td style={{ textAlign: "right" }}>{item.vat_rate}%</td>
                )}
                {item.vat?.vat_base !== null &&
                  item.vat?.vat_base !== undefined && (
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      {formatPrice(item.vat.vat_base)}
                    </td>
                  )}
                {item.vat?.vat_amount !== null &&
                  item.vat?.vat_amount !== undefined && (
                    <td style={{ textAlign: "right" }}>
                      {formatPrice(item.vat.vat_amount)}
                    </td>
                  )}
                <td style={{ textAlign: "right" }}>
                  {formatPrice(item.total_price)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {formatPrice(item.total_price_including_vat)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="summary-columns">
        {vat_summary &&
          Array.isArray(vat_summary.vat_rate) &&
          vat_summary.vat_rate.length > 0 && (
            <div className="vat-summary">
              <table>
                <thead>
                  <tr>
                    <th>Sazba DPH</th>
                    <th>Základ DPH</th>
                    <th>Výše DPH</th>
                  </tr>
                </thead>
                <tbody>
                  {vat_summary.vat_rate.map((rate, index) => (
                    <tr key={index}>
                      <td>{rate}%</td>
                      <td>{vat_summary.vat_base[index]}</td>
                      <td>{vat_summary.vat_amount[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        <div className="invoice-summary">
          <table>
            <tbody>
              <tr>
                <td>Mezisoučet:</td>
                <td>{total_net}</td>
              </tr>

              <tr>
                <td>DPH:</td>
                <td>{total_vat}</td>
              </tr>

              {prepaid_amount && (
                <tr>
                  <td>Předplacená částka:</td>
                  <td>{prepaid_amount} </td>
                </tr>
              )}
              {total_after_prepayment && (
                <tr>
                  <td>Celkem po odečtení zálohy:</td>
                  <td>{total_after_prepayment} </td>
                </tr>
              )}
              <tr>
                <td>Celkem:</td>
                <td>{total_gross} </td>
              </tr>
              <tr>
                <td>Zaokrouhlení:</td>
                <td>
                  {rounding !== undefined && rounding !== null ? rounding : 0}{" "}
                </td>
              </tr>

              {amount_due !== null && (
                <tr className="total">
                  <td>
                    <strong>Částka k úhradě:</strong>
                  </td>
                  <td>{amount_due} </td>
                </tr>
              )}

              {paid_amount !== null && (
                <tr>
                  <td>
                    <strong>Zaplacená částka:</strong>
                  </td>
                  <td>{paid_amount} </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="invoice-footer">
        {(iban || bic_swift || payment_status) && (
          <div className="payment-details">
            <h3>Platební údaje</h3>
            {iban && (
              <p>
                <strong>IBAN:</strong> {iban}
              </p>
            )}
            {bic_swift && (
              <p>
                <strong>BIC/SWIFT:</strong> {bic_swift}
              </p>
            )}
            {payment_status && (
              <p>
                <strong>Status platby:</strong> {payment_status}
              </p>
            )}
          </div>
        )}

        <div className="additional-info">
          <h3>Dodatečné informace</h3>
          {warranty_period && (
            <p>
              <strong>Záruční doba:</strong> {warranty_period}
            </p>
          )}

          {contact && (
            <p>
              <strong>Kontakt:</strong>
              {typeof contact === "string" ? (
                <span>{contact}</span>
              ) : (
                <>
                  {contact.email && <span>{contact.email}</span>}
                  {contact.phone && <span>{contact.phone}</span>}
                </>
              )}
            </p>
          )}
          {additional_info && (
            <div>
              <strong>Dodatečné informace:</strong>
              {typeof additional_info === "string" ? (
                // If additional_info is a string, render it directly
                <p>{additional_info}</p>
              ) : (
                // If additional_info is an object, map through its keys and values
                Object.entries(additional_info).map(([key, value]) => (
                  <div key={key}>
                    {typeof value === "object" && value !== null ? (
                      // If the value is an object, map through its keys and values
                      <div>
                        <strong>{key}:</strong>
                        {Object.entries(value).map(([subKey, subValue]) => (
                          <div key={subKey}>
                            <strong>{subKey}:</strong> {subValue}
                          </div>
                        ))}
                      </div>
                    ) : (
                      // Otherwise, just render the key and value
                      <div>
                        <strong>{key}:</strong> {value}
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
