import React, { useEffect, useState } from "react";
import { getDeviceStatus, controlRelay } from "../../shellyApi";
import { notification } from "antd";
import { useAuth } from "oidc-react";
import "./DeviceControl.scss";

const LevaClonaId = process.env.REACT_APP_SHELLY_LEVACLONA;
const PravaClonaId = process.env.REACT_APP_SHELLY_PRAVACLONA;
const IceMakerId = process.env.REACT_APP_SHELLY_ICEMAKER;

function DeviceControl() {
  const [isLoading, setIsLoading] = useState(true);
  const [levaClonaStatus, setLevaClonaStatus] = useState(false);
  const [pravaClonaStatus, setPravaClonaStatus] = useState(false);
  const [iceMakerStatus, setIceMakerStatus] = useState(false);
  const [tempLevaClona, setTempLevaClona] = useState(null);
  const [tempPravaClona, setTempPravaClona] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const auth = useAuth();
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.employee);

  const checkTime = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    return currentHour >= 19 || currentHour < 10;
  };

  const fetchStatus = async () => {
    try {
      const statusLevaClona = await getDeviceStatus(LevaClonaId);
      const statusPravaClona = await getDeviceStatus(PravaClonaId);
      const statusIceMaker = await getDeviceStatus(IceMakerId);

      setLevaClonaStatus(
        statusLevaClona?.data?.device_status?.["switch:0"]?.output || false
      );
      setPravaClonaStatus(
        statusPravaClona?.data?.device_status?.["switch:0"]?.output || false
      );
      setIceMakerStatus(
        statusIceMaker?.data?.device_status?.["switch:0"]?.output || false
      );

      setTempLevaClona(
        statusLevaClona?.data?.device_status?.["switch:0"]?.temperature?.tC ||
          "N/A"
      );
      setTempPravaClona(
        statusPravaClona?.data?.device_status?.["switch:0"]?.temperature?.tC ||
          "N/A"
      );
    } catch (error) {
      console.error("Error fetching device status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
    setIsDisabled(checkTime());

    const interval = setInterval(() => {
      fetchStatus();
    }, 3000); // Obnovuje stav každé 3 sekundy

    return () => clearInterval(interval);
  }, []);

  const handleControl = async (deviceId, deviceName) => {
    try {
      await controlRelay(deviceId, 0, "toggle");
      openNotification(deviceName, "Stav zařízení byl změněn");
      fetchStatus();
    } catch (error) {
      openNotification(deviceName, "Nepodařilo se změnit stav zařízení", true);
    }
  };

  const openNotification = (title, description, isError = false) => {
    notification[isError ? "error" : "success"]({
      message: title,
      description: description,
      placement: "bottomRight",
      duration: 5,
    });
  };

  const LedIndicator = ({ isOn }) => {
    const indicatorStyle = isOn ? "led-indicator-on" : "led-indicator-off";
    return <div className={`led-indicator ${indicatorStyle}`}></div>;
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="deviceControlContainer">
      <h3 className="sectionTitle">Stav zařízení</h3>
      <div className="deviceColumns">
        <div className="deviceColumn">
          <h4>Levá vzduchová clona</h4>
          <div className="statusRow">
            <span>Připojení:</span>
            <LedIndicator isOn={levaClonaStatus} />
          </div>
          {!isEmployee && (
            <div className="statusRow">
              <span>Teplota:</span>
              <span>{tempLevaClona}°C</span>
            </div>
          )}
          <button
            onClick={() => handleControl(LevaClonaId, "Levá vzduchová clona")}
            disabled={isDisabled}
          >
            Přepnout
          </button>
        </div>

        <div className="deviceColumn">
          <h4>Pravá vzduchová clona</h4>
          <div className="statusRow">
            <span>Připojení:</span>
            <LedIndicator isOn={pravaClonaStatus} />
          </div>
          {!isEmployee && (
            <div className="statusRow">
              <span>Teplota:</span>
              <span>{tempPravaClona}°C</span>
            </div>
          )}
          <button
            onClick={() => handleControl(PravaClonaId, "Pravá vzduchová clona")}
            disabled={isDisabled}
          >
            Přepnout
          </button>
        </div>

        <div className="deviceColumn">
          <h4>Výrobník ledu</h4>
          <div className="statusRow">
            <span>Připojení:</span>
            <LedIndicator isOn={iceMakerStatus} />
          </div>
          <button
            onClick={() => handleControl(IceMakerId, "Výrobník ledu")}
            disabled={isDisabled}
          >
            Přepnout
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeviceControl;
