const API_BASE_URL = 'https://api.lootea.cz/api';

async function callApi(endpoint, method = 'GET', data = null) {
    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_ADMIN_API_KEY,
        'Accept-Encoding': 'gzip, deflate, br'
    };

    const options = {
        method,
        headers
    };

    if (data) {
        options.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(`${API_BASE_URL}/${endpoint}`, options);

        const responseData = await response.json();

        if (!response.ok) {
            return { error: true, message: responseData.message || `HTTP error! status: ${response.status}`, statusCode: response.status };
        }

        return responseData;
    } catch (error) {
        console.error('Error calling API:', error);
        return { error: true, message: error.message, statusCode: error.status || 500 };
    }
}

export async function uploadImage(formData) {
    const headers = {
        'x-api-key': process.env.REACT_APP_ADMIN_API_KEY,
    };

    try {
        const response = await fetch(`${API_BASE_URL}/upload`, {
            method: 'POST',
            headers,
            body: formData,
        });

        const responseData = await response.json();

        if (!response.ok) {
            return { error: true, message: responseData.message || `HTTP error! status: ${response.status}`, statusCode: response.status };
        }

        return responseData;
    } catch (error) {
        console.error('Error uploading image:', error);
        return { error: true, message: error.message, statusCode: error.status || 500 };
    }
}

export default callApi;
