import React, { useState, useEffect } from "react";
import { Form, Table, Radio, Input, Button, Drawer, InputNumber } from "antd";
import "./paymentTypes.scss";
import callApi from "../../ApiCaller";

const { Search } = Input;

const PaymentTypes = () => {
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [size, setSize] = useState("large");
  const [isModalVisible, setIsModalVisible] = useState(false); // Stav pro modální okno
  const [form] = Form.useForm();

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleSearch = () => {
    const searchData = apiData.filter((item) => {
      return Object.values(item).some((value) =>
        value
          ? value.toString().toLowerCase().includes(searchValue.toLowerCase())
          : false
      );
    });
    setFilteredData(searchData);
  };

  const handleResetSearch = () => {
    setFilteredData(apiData);
    setSearchValue("");
  };

  useEffect(() => {
    callApi("payment-types").then((data) => {
      setApiData(data);
      setFilteredData(data);
    });
  }, []);

  const columns =
    apiData.length > 0
      ? [
          {
            title: "Id",
            dataIndex: "Id",
            sorter: (a, b) => a.Id - b.Id,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "Název",
            dataIndex: "Name",
            sorter: (a, b) => a.Name.localeCompare(b.Name),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: apiData.map((item) => ({
              text: item.Name,
              value: item.Name,
            })),
            onFilter: (value, record) => record.Name === value,
          },
          {
            title: "Pořadí",
            dataIndex: "Ordinal",
            sorter: (a, b) => a.Ordinal - b.Ordinal,
            sortDirections: ["ascend", "descend", "ascend"],
            filters: apiData.map((item) => ({
              text: item.Ordinal,
              value: item.Ordinal,
            })),
            onFilter: (value, record) => record.Ordinal === value,
          },
          {
            title: "Povoleno",
            dataIndex: "IsEnabled",
            render: (text) => {
              return (
                <div className={`icon-cell ${text ? "enabled" : "disabled"}`}>
                  {text ? "✔" : "❌"}
                </div>
              );
            },
            sorter: (a, b) => a.IsEnabled - b.IsEnabled,
            sortDirections: ["ascend", "descend", "ascend"],
            filters: [
              {
                text: "✔",
                value: 1,
              },
              {
                text: "❌",
                value: 0,
              },
            ],
            onFilter: (value, record) => record.IsEnabled === value,
          },
          {
            title: "Rychlá platba",
            dataIndex: "IsQuickPayment",
            render: (text) => {
              return (
                <div className={`icon-cell ${text ? "enabled" : "disabled"}`}>
                  {text ? "✔" : "❌"}
                </div>
              );
            },
            sorter: (a, b) => a.IsQuickPayment - b.IsQuickPayment,
            sortDirections: ["ascend", "descend", "ascend"],
            filters: [
              {
                text: "✔",
                value: 1,
              },
              {
                text: "❌",
                value: 0,
              },
            ],
            onFilter: (value, record) => record.IsQuickPayment === value,
          },
          {
            title: "Otevřít kasu",
            dataIndex: "OpenCashDrawer",
            render: (text) => {
              return (
                <div className={`icon-cell ${text ? "enabled" : "disabled"}`}>
                  {text ? "✔" : "❌"}
                </div>
              );
            },
            sorter: (a, b) => a.OpenCashDrawer - b.OpenCashDrawer,
            sortDirections: ["ascend", "descend", "ascend"],
            filters: [
              {
                text: "✔",
                value: 1,
              },
              {
                text: "❌",
                value: 0,
              },
            ],
            onFilter: (value, record) => record.OpenCashDrawer === value,
          },
        ]
      : [];

  const formItems = columns
    .filter((column) => column.dataIndex !== "Id") // Vyloučit sloupec "Id"
    .map((column) => {
      if (
        column.dataIndex === "IsEnabled" ||
        column.dataIndex === "IsQuickPayment" ||
        column.dataIndex === "OpenCashDrawer"
      ) {
        return (
          <Form.Item
            key={column.dataIndex}
            label={column.title}
            name={column.dataIndex}
          >
            <Radio.Group>
              <Radio value={true}>Ano</Radio>
              <Radio value={false}>Ne</Radio>
            </Radio.Group>
          </Form.Item>
        );
      } else if (column.dataIndex === "Ordinal") {
        return (
          <Form.Item
            key={column.dataIndex}
            label={column.title}
            name={column.dataIndex}
            rules={[{ type: "number", message: "Zadejte číslo" }]}
          >
            <InputNumber placeholder="0" />
          </Form.Item>
        );
      } else {
        return (
          <Form.Item
            key={column.dataIndex}
            label={column.title}
            name={column.dataIndex}
          >
            <Input placeholder="Název" />
          </Form.Item>
        );
      }
    });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddRow = () => {
    const formData = form.getFieldsValue(); // Získání hodnot z formuláře
    // Zde můžete implementovat volání API pro přidání nového řádku s formData.
    // Po úspěšném přidání můžete aktualizovat data a zavřít modální okno.
    // Například:
    // axios.post("http://localhost:3000/payment-types", formData).then((response) => {
    //   setApiData([...apiData, response.data]);
    //   handleCancel();
    // });
  };

  const data = filteredData.map((item) => ({
    ...item,
    key: item.Id,
  }));

  return (
    <>
    <h1>Druhy plateb</h1>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 16,
        }}
      >
        <Form.Item>
          <Search
            placeholder="Search"
            onSearch={handleSearch}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
          <Button onClick={handleResetSearch}>Reset</Button>
        </Form.Item>

        <Form.Item>
          <Radio.Group value={size} onChange={handleSizeChange}>
            <Radio.Button value="large">Large</Radio.Button>
            <Radio.Button value="middle">Middle</Radio.Button>
            <Radio.Button value="small">Small</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button onClick={showModal}>Přidat</Button>
        </Form.Item>
      </Form>
      <Table
        size={size}
        dataSource={data}
        columns={columns}
        rowKey="Id"
        pagination={false}
      />
      <Drawer
        title="Přidat nový řádek"
        open={isModalVisible}
        onClose={handleCancel}
        width={400} // Zde můžete nastavit šířku podle svých potřeb
        footer={
          <div style={{ textAlign: "right" }}>
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Zrušit
            </Button>
            <Button onClick={handleAddRow} type="primary">
              Přidat
            </Button>
          </div>
        }
      >
        <Form>{formItems}</Form>
      </Drawer>
    </>
  );
};

export default PaymentTypes;
