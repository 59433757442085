import { useState, useEffect } from "react";
import "./topCustomersBox.scss";
import { useSocket } from "../../SocketProvider";

const TopCustomers = () => {
  const [topCustomers, setTopCustomers] = useState([]);

  const socket = useSocket();

  const fetchTopCustomers = () => {
    const query = `
                    SELECT c.Name, c.email, d.CustomerId, SUM(d.Total) AS TotalPurchases
                    FROM Document AS d
                    INNER JOIN Customer AS c ON d.CustomerId = c.Id
                    WHERE c.Id <> 1
                    GROUP BY d.CustomerId
                    ORDER BY TotalPurchases DESC
                    LIMIT 5;
                  `;

    socket.emit("fetch-top-customers", { query });
  };

  useEffect(() => {
    fetchTopCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("top-customers", (data) => {
      setTopCustomers(data);
    });

    return () => {
      socket.off("top-monthly-customers"); // Unsubscribe when component unmounts
    };
  }, [socket]);

  // Funkce pro získání URL ikony na základě jména zákazníka
  function getIconUrl(name) {
    const lowercaseName = name.toLowerCase();
    const iconWords = [
      "aau",
      "lootea",
      "švejk",
      "bagel",
      "follow bubble",
      "jelínek",
      "alebrijes",
    ]; // Přidejte další slova podle vašich ikon

    for (const word of iconWords) {
      if (lowercaseName.includes(word)) {
        return `/customers_icons/${word}.png`;
      }
    }

    // Pokud se žádná shoda nenajde, použijte výchozí ikonu noavatar.png
    return "/noavatar.png";
  }

  return (
    <div className="topBox">
      <h1>Nejlepší zákazník</h1>
      <div className="list">
        {topCustomers.map((user) => (
          <div className="listItem" key={user.CustomerId}>
            <div className="user">
              {/* Zde použijete funkci getIconUrl pro získání URL ikony */}
              <img src={getIconUrl(user.Name)} alt={user.Name} />
              <div className="userTexts">
                <span className="username">{user.Name}</span>
                <span className="email">{user.Email}</span>
              </div>
            </div>
            <span className="amount">{Math.round(user.TotalPurchases)} Kč</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopCustomers;
