import React, { useState, useEffect } from "react";
import { Table, Select, Input, DatePicker, notification } from "antd";
import { useSocket } from "../../SocketProvider";
import dayjs from "dayjs";
import "dayjs/locale/cs";
import locale from "antd/es/date-picker/locale/cs_CZ";

import "./posTransaction.scss";
const { RangePicker } = DatePicker;

const PosTransaction = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [editingSubcategory, setEditingSubcategory] = useState({
    rowId: null,
    categoryId: null,
  });
  const socket = useSocket();

  useEffect(() => {
    setLoading(true);
    socket.emit("pos_transaction", {
      operation: "READ",
      payload: { someValue: "*" },
    });

    socket.on("pos_transaction_data", (rows) => {
      const formattedData = rows
        .map((row) => ({
          ...row,
          key: row.ID,
          TypOperaceDisplay:
            row.TypOperace === "Vyber"
              ? "Výběr ➖"
              : row.TypOperace === "Vklad"
              ? "Vklad ➕"
              : row.TypOperace,
        }))
        .sort((a, b) => (dayjs(b.Datum).isAfter(dayjs(a.Datum)) ? 1 : -1));
      setData(formattedData);
    });

    socket.on("pos_transaction_update_category_success", (data) => {
      if (data.success) {
        notification.success({
          message: "Aktualizace byla úspěšná",
          placement: "bottomRight",
          duration: 2,
        });
      } else {
        notification.error({
          message: "Chyba při ukládání",
          placement: "bottomRight",
          duration: 2,
        });
      }
    });
    // Fetch categories and subcategories
    socket.emit("pos_transaction", {
      operation: "FETCH_CATEGORIES_SUBCATEGORIES",
    });

    socket.on("categories_subcategories_data", (categories) => {
      let categoryOptions = categories
        .filter((c) => c.ID_Parent === null)
        .map((category) => ({ label: category.Name, value: category.ID }));

      let subCategoryOptions = categories
        .filter((c) => c.ID_Parent !== null)
        .map((subCategory) => ({
          label: subCategory.Name,
          value: subCategory.ID,
          parent: subCategory.ID_Parent,
        }));

      // Kontrola, zda "Nezařazeno" již existuje, než ho přidáme
      if (!subCategoryOptions.some((option) => option.value === 37)) {
        subCategoryOptions.push({
          label: "Nezařazeno",
          value: 37,
          parent: null,
        });
      }

      // Seřazení a aktualizace stavu
      const sortOptions = (a, b) =>
        a.label === "Nezařazeno"
          ? -1
          : b.label === "Nezařazeno"
          ? 1
          : a.label.localeCompare(b.label);
      categoryOptions = categoryOptions.sort(sortOptions);
      subCategoryOptions = subCategoryOptions.sort(sortOptions);

      setCategoryOptions(categoryOptions);
      setSubCategoryOptions(subCategoryOptions);

      setLoading(false);
    });

    return () => {
      socket.off("pos_transaction_data");
      socket.off("pos_transaction_update_category_success");
      socket.off("categories_subcategories_data");
    };
  }, [socket]);

  const handleCategoryChange = (newCategoryId, record) => {
    if (newCategoryId) {
      const categoryLabel =
        categoryOptions.find((option) => option.value === newCategoryId)
          ?.label || "Neznámá kategorie";

      const updatedData = data.map((item) => {
        if (item.key === record.key) {
          return {
            ...item,
            Kategorie: categoryLabel,
            ID_Kategorie: newCategoryId,
          };
        }
        return item;
      });
      setData(updatedData);

      // Odeslání aktualizované hodnoty na server
      socket.emit("pos_transaction", {
        operation: "UPDATE_CATEGORY",
        payload: { ID: record.ID, newCategoryId: newCategoryId },
      });

      // Aktualizace editingSubcategory pro reflexi změny kategorie
      setEditingSubcategory((prev) => ({ ...prev, categoryId: newCategoryId }));

      // Ukončení editace
      setEditingCategoryId(null);
    }
  };
  const handleSubcategoryChange = (newSubcategoryId, record) => {
    if (newSubcategoryId) {
      // Správně vyhledejte název podkategorie z `subCategoryOptions`
      const subCategoryLabel =
        subCategoryOptions.find((option) => option.value === newSubcategoryId)
          ?.label || "Neznámá subkategorie";

      const updatedData = data.map((item) => {
        if (item.key === record.key) {
          // Ujistěte se, že aktualizujete Podkategorie s názvem, nikoli s ID
          return { ...item, Podkategorie: subCategoryLabel };
        }
        return item;
      });
      setData(updatedData);

      // Odeslání aktualizované hodnoty na server
      socket.emit("pos_transaction", {
        operation: "UPDATE_SUBCATEGORY",
        payload: { ID: record.ID, newSubcategoryId: newSubcategoryId },
      });

      // Ukončení editace
      setEditingCategoryId(null); // Možná budete chtít mít zvláštní stav pro ukončení úprav podkategorie
    }
  };
  const uniqueOperations = Array.from(
    new Set(data.map((item) => item.TypOperaceDisplay))
  );

  const uniqueCategories = Array.from(
    new Set(data.map((item) => item.Kategorie))
  );
  const uniqueSubCategories = Array.from(
    new Set(data.map((item) => item.Podkategorie))
  );
  const operationFilters = uniqueOperations.map((operation) => ({
    text: operation,
    value: operation,
  }));
  const categoryFilters = uniqueCategories.sort().map((category) => ({
    text: category,
    value: category,
  }));
  const subCategoryFilters = uniqueSubCategories.sort().map((subCategory) => ({
    text: subCategory,
    value: subCategory,
  }));

  // Filtrujte data podle vybraného data
  const filteredData = data.filter((item) => {
    const { Datum, Popis, Castka } = item;
    const searchTextLower = searchText.toLowerCase();

    // Parse the transaction date as a Day.js object from ISO string
    const transactionDate = dayjs(Datum);

    // Filtrace podle textu
    const textMatch =
      Popis.toLowerCase().includes(searchTextLower) ||
      Castka.toString().includes(searchTextLower);

    // Filtrace podle data
    let dateMatch = true;
    if (selectedDateRange && selectedDateRange.length === 2) {
      const startDate = selectedDateRange[0];
      const endDate = selectedDateRange[1].add(1, "day");
      dateMatch =
        transactionDate.isSameOrAfter(startDate, "day") &&
        transactionDate.isBefore(endDate, "day");
    }

    return textMatch && dateMatch;
  });

  const startEditingSubcategory = (record) => {
    setEditingSubcategory({
      rowId: record.key,
      categoryId: record.ID_Kategorie,
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      width: 70,
    },
    {
      title: "Datum",
      dataIndex: "Datum",
      key: "Datum",
      width: 170,
      sorter: (a, b) => (dayjs(a.Datum).isAfter(dayjs(b.Datum)) ? -1 : 1),
      render: (text) => dayjs(text).format("DD. MM. YYYY"),
    },
    {
      title: "Vklad/Výběr",
      dataIndex: "TypOperaceDisplay",
      key: "TypOperaceDisplay",
      width: 100,
      filters: operationFilters,
      onFilter: (value, record) => record.TypOperaceDisplay === value,
    },

    {
      title: "Částka",
      dataIndex: "Castka",
      key: "Castka",
      width: 120,
      sorter: (a, b) => a.Castka - b.Castka,
    },
    {
      title: "Kategorie",
      dataIndex: "Kategorie",
      key: "Kategorie",
      width: 230,
      filters: categoryFilters,
      onFilter: (value, record) => record.Kategorie === value,
      render: (text, record) => {
        if (editingCategoryId === record.key) {
          return (
            <Select
              defaultValue={text}
              showSearch={true}
              style={{ width: 200 }}
              onChange={(value) => handleCategoryChange(value, record)}
              onBlur={() => setEditingCategoryId(null)}
              filterOption={(input, option) => {
                if (option && option.children) {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }
                return false;
              }}
            >
              {categoryOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return (
          <div
            onClick={() => setEditingCategoryId(record.key)}
            style={{ cursor: "pointer" }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Podkategorie",
      dataIndex: "Podkategorie",
      key: "Podkategorie",

      width: 230,
      filters: subCategoryFilters,
      onFilter: (value, record) => record.ID_Kategorie === value,

      render: (text, record) => {
        if (editingSubcategory.rowId === record.key) {
          const options = subCategoryOptions.filter(
            (option) =>
              option.parent === editingSubcategory.categoryId ||
              option.value === 37 // Zahrnout "Nezařazeno"
          );
          return (
            <Select
              defaultValue={text}
              showSearch={true}
              style={{ width: 200 }}
              onChange={(value) => handleSubcategoryChange(value, record)}
              onBlur={() =>
                setEditingSubcategory({ rowId: null, categoryId: null })
              }
              filterOption={(input, option) => {
                if (option && option.children) {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }
                return false;
              }}
            >
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return (
          <div
            onClick={() => startEditingSubcategory(record)}
            style={{ cursor: "pointer" }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Popis",
      dataIndex: "Popis",
      key: "Popis",
    },
  ];

  return (
    <div>
      <h1>Pokladní Transakce</h1>

      <Input
        placeholder="Vyhledat"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 10, width: "250px" }}
      />
      <RangePicker
        placeholder={["Od", "Do"]}
        locale={locale}
        value={selectedDateRange}
        onChange={(dates) => setSelectedDateRange(dates)}
        style={{ marginBottom: 10, marginLeft: 45, width: "250px" }}
      />
      <Table
        dataSource={filteredData}
        columns={columns}
        loading={loading}
        rowClassName={(record) =>
          record.Kategorie === "Nezařazeno"
            ? "row-danger"
            : "" || record.Podkategorie === "Nezařazeno"
            ? "row-danger"
            : ""
        }
      />
    </div>
  );
};

export default PosTransaction;
