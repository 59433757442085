import { Link } from "react-router-dom";
import { useAuth } from "oidc-react";

import "./menu.scss";
// ✅❌🔜🛠️🆗
// hotovo ✅ / nic
// brzy 🔜
// potreba opravit 🛠️
// funguje na pracovni urovni 🆗
// nefunguje ❌

// menu pro admina
const menu = [
  {
    id: 1,
    title: "Management",
    listItems: [
      {
        id: 0,
        title: "Přehled ",
        url: "/",
        icon: "home.svg",
      },
      {
        id: 1,
        title: "Přehled pro zaměstnance ",
        url: "/homeemployee",
        icon: "home.svg",
      },
      {
        id: 2,
        title: "Denní uzávěrka",
        url: "/DailyClosure",
        icon: "calculator.svg",
      },
      {
        id: 3,
        title: "Informace ",
        url: "/company-info",
        icon: "element.svg",
      },
      {
        id: 4,
        title: "Kalendář",
        url: "/calendar",
        icon: "calendar.svg",
      },
      {
        id: 5,
        title: "Zaměstnanci",
        url: "/employees",
        icon: "user.svg",
      },
      {
        id: 6,
        title: "Směny",
        url: "/shift-sheduler",
        icon: "calendar.svg",
      },
      {
        id: 7,
        title: "Zásoby",
        url: "/storage",
        icon: "calculator.svg",
      },
      {
        id: 8,
        title: "Recepty",
        url: "/recipes",
        icon: "note.svg",
      },
      {
        id: 9,
        title: "FAQ",
        url: "/faq",
        icon: "post2.svg",
      },
    ],
  },

  {
    id: 3,
    title: "Reporty",
    listItems: [
      {
        id: 1,
        title: "Denní prodeje ",
        url: "/daily-sales",
        icon: "post2.svg",
      },
      {
        id: 2,
        title: "Prodeje produktů",
        url: "/sales-by-products",
        icon: "post2.svg",
      },
      {
        id: 3,
        title: "Prodeje dle daně",
        url: "/sales-by-taxes",
        icon: "post2.svg",
      },
    ],
  },
  {
    id: 4,
    title: "Databáze",
    listItems: [
      {
        id: 1,
        title: "Zákazníci",
        url: "/customers",
        icon: "user.svg",
      },
      {
        id: 2,
        title: "Produkty ",
        url: "/products",
        icon: "product.svg",
      },
      {
        id: 3,
        title: "Dokumenty",
        url: "/documents",
        icon: "documents.svg",
      },
    ],
  },

  {
    id: 6,
    title: "Účetnictví",
    listItems: [
      {
        id: 1,
        title: "Pokladní transakce",
        url: "/pos-transaction",
        icon: "form.svg",
      },
      {
        id: 2,
        title: "Bankovní transakce",
        url: "/bank-transaction",
        icon: "form.svg",
      },
      {
        id: 3,
        title: "Faktury",
        url: "/invoices",
        icon: "post.svg",
      },
      /*  {
        id: 4,
        title: "Zálohy",
        url: "/backups",
        icon: "backup.svg",
      }, */
    ],
  },
  /* {
    id: 5,
    title: "DashBoard", // U lužického semináře
    listItems: [
      {
        id: 1,
        title: "Přehled ✅",
        url: "/homeemployee",
        icon: "chart.svg",
      },
      {
        id: 3,
        title: "Denní uzávěrka✅",
        url: "/dailyclosure",
        icon: "note.svg",
      },
      // {
      //   id: 2,
      //   title: "Logs",
      //   url: "/",
      //   icon: "log.svg",
      // },
      // {
      //   id: 7,
      //   title: "Notes",
      //   url: "/",
      //   icon: "note.svg",
      // },
      // {
      //   id: 6,
      //   title: "Forms",
      //   url: "/",
      //   icon: "form.svg",
      // },
    ],
  }, */
];

// menu pro zaměstnance
const menuEmployee = [
  {
    id: 1,
    title: "DashBoard", // U lužického semináře
    listItems: [
      {
        id: 1,
        title: "Přehled ",
        url: "/homeemployee",
        icon: "chart.svg",
      },
      {
        id: 2,
        title: "Denní uzávěrka",
        url: "/DailyClosure",
        icon: "note.svg",
      },
      {
        id: 3,
        title: "Směny",
        url: "/shift-sheduler",
        icon: "calendar.svg",
      },
      {
        id: 4,
        title: "Zásoby",
        url: "/storage",
        icon: "calculator.svg",
      },
      {
        id: 5,
        title: "Recepty",
        url: "/recipes",
        icon: "note.svg",
      },
      {
        id: 6,
        title: "FAQ ",
        url: "/faq",
        icon: "post2.svg",
      },
    ],
  },
];
// menu pro Investory
const menuInvestor = [
  {
    id: 1,
    title: "main",
    listItems: [
      {
        id: 0,
        title: "Přehled",
        url: "/",
        icon: "home.svg",
      },
    ],
  },
];

const Menu = ({ role }) => {
  const auth = useAuth();
  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.employee);
  const isInvestor = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.investor);

  let displayMenu;
  if (isEmployee) {
    displayMenu = menuEmployee;
  } else if (isInvestor) {
    displayMenu = menuInvestor;
  } else {
    displayMenu = menu;
  }

  if (!isEmployee && !isInvestor) {
    if (role === "Employee") {
      displayMenu = menuEmployee;
    } else if (role === "Investor") {
      displayMenu = menuInvestor;
    } else {
      displayMenu = menu;
    }
  }

  return (
    <div className="menu">
      {displayMenu.map((item) => (
        <div className="item" key={item.id}>
          <span className="title">{item.title}</span>
          {item.listItems.map((listItem) => (
            <Link to={listItem.url} className="listItem" key={listItem.id}>
              <img src={listItem.icon} alt="" />
              <span className="listItemTitle">{listItem.title}</span>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Menu;
