import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useEffect, useState } from "react";
import "./pieChartBox.scss";
import callApi from "../../ApiCaller";
import { Select } from "antd";

const currentDate = new Date();
const currentYear = currentDate.getFullYear().toString();
const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");

const PieChartBox = () => {
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const categoryColors = {
    categoryId1: "#FFA07A", // Lososová
    categoryId2: "#98FB98", // Světle zelená
    categoryId3: "#FF69B4", // Růžová
    categoryId4: "#FFD700", // Zlatá
    categoryId5: "#ADD8E6", // Světle modrá
    categoryId6: "#FF6347", // Rajčatová červená
    categoryId7: "#9370DB", // Modrá lila
    categoryId8: "#87CEEB", // Světle modrozelená
    categoryId9: "#20B2AA", // Azurová
    categoryId10: "#FF8C00", // Tmavě oranžová
    categoryId11: "#DDA0DD", // Světle švestková
    categoryId12: "#90EE90", // Světle zelená
    categoryId13: "#40E0D0", // Týrkysová
    categoryId14: "#FF4500", // Oranžová červená
    categoryId15: "#8A2BE2", // Fialová
    categoryId16: "#FFFFF0", // Slonovinová
    categoryId17: "#20B2AA", // Azurová
    categoryId18: "#FFD700", // Zlatá
    categoryId19: "#98FB98", // Světle zelená
    categoryId20: "#FF69B4", // Růžová
    categoryId21: "#9370DB", // Modrá lila
    categoryId22: "#FF6347", // Rajčatová červená
    categoryId23: "#FFA500", // Oranžová
    categoryId24: "#FF4500", // Oranžová červená
    categoryId25: "#87CEEB", // Světle modrozelená
    categoryId26: "#DDA0DD", // Světle švestková
    categoryId27: "#40E0D0", // Týrkysová
    categoryId28: "#8A2BE2", // Fialová
    categoryId29: "#FFFFF0", // Slonovinová
    categoryId30: "#90EE90", // Světle zelená
  };

  const assignColorToCategory = (categoryId) => {
    return categoryColors[`categoryId${categoryId}`] || "#000000";
  };
  useEffect(() => {
    callApi("totals-by-category")
      .then((apiData) => {
        // Filtrujte null hodnoty a extrahujte unikátní roky a měsíce
        const filteredData = apiData.filter((item) => item.Year && item.Month);

        const uniqueYears = Array.from(
          new Set(filteredData.map((item) => item.Year))
        ).sort();
        setYears(uniqueYears);

        const uniqueMonths = Array.from(
          new Set(filteredData.map((item) => item.Month))
        ).sort();
        setMonths(uniqueMonths);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      callApi(`totals-by-category?year=${selectedYear}&month=${selectedMonth}`)
        .then((apiData) => {
          setData(apiData);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
    }
  }, [selectedYear, selectedMonth]);
  const getCategoryNameRows = (categoryName) => {
    const maxCharactersPerRow = 15;
    const words = categoryName.split(" ");
    let currentRow = "";
    const rows = [];

    for (let i = 0; i < words.length; i++) {
      if (currentRow.length + words[i].length <= maxCharactersPerRow) {
        currentRow += words[i] + " ";
      } else {
        rows.push(currentRow.trim());
        currentRow = words[i] + " ";
      }
    }

    rows.push(currentRow.trim()); // Add the last row

    return rows;
  };

  const formatCurrency = (value) => {
    return Math.round(value).toLocaleString("cs-CZ", {
      style: "currency",
      currency: "CZK",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const renderYearSelector = () => {
    return (
      <Select
        value={selectedYear}
        style={{ width: 120 }}
        onChange={setSelectedYear}
      >
        {years.map((year) => (
          <Select.Option key={year} value={year}>
            {year}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderMonthSelector = () => {
    let availableMonths = months;
    if (selectedYear === currentYear) {
      const currentMonthIndex = new Date().getMonth() + 1; // Aktuální měsíc jako číslo
      availableMonths = months.filter(
        (month) => parseInt(month, 10) <= currentMonthIndex
      );
    }

    return (
      <Select
        value={selectedMonth}
        style={{ width: 120 }}
        onChange={setSelectedMonth}
      >
        {availableMonths.map((month) => (
          <Select.Option key={month} value={month}>
            {month}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderOptions = () => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <div
          className="option"
          key={`${selectedYear}-${selectedMonth}-${item.Category}`}
        >
          <div className="title">
            <div
              className="dot"
              style={{
                backgroundColor: assignColorToCategory(item.CategoryId),
              }}
            />
            <div
              className="category-name"
              style={{ color: assignColorToCategory(item.CategoryId) }}
            >
              {getCategoryNameRows(item.Category).map((row, index) => (
                <div key={index}>{row}</div>
              ))}
            </div>
          </div>
          <span>{formatCurrency(item.Total)}</span>
        </div>
      ));
    } else {
      return <div className="no-data">Žádné data</div>;
    }
  };

  return (
    <div className="pieChartBox">
      <h1>Kategorie</h1>
      <div className="selectors">
        {renderMonthSelector()}
        {renderYearSelector()}
      </div>
      <div className="chart">
        <ResponsiveContainer width="99%" height={300}>
          <PieChart>
            <Tooltip
              contentStyle={{ background: "white", borderRadius: "5px" }}
              formatter={(value, name) => [formatCurrency(value), name]}
            />
            <Pie
              data={data}
              innerRadius={"70%"}
              outerRadius={"90%"}
              paddingAngle={5}
              dataKey="Total"
              nameKey="Category"
              valueKey="Total"
            >
              {data.map((item, index) => (
                <Cell
                  key={item.Category}
                  fill={assignColorToCategory(item.CategoryId)}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="options">{renderOptions()}</div>
    </div>
  );
};
export default PieChartBox;
