import React, { useState, useEffect } from "react";
import "./actualChartbox.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { Select } from "antd";
import callApi from "../../ApiCaller";
import { useSocket } from "../../SocketProvider";

const ActualChartbox = () => {
  const today = new Date();
  const formatNumber = (number) => (number < 10 ? `0${number}` : number);
  const currentDay = formatNumber(today.getDate());
  const currentMonth = formatNumber(today.getMonth() + 1);
  const currentYear = today.getFullYear();

  const [data, setData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(currentDay);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [availableDays, setAvailableDays] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);

  const socket = useSocket();

  const totalRevenue = data.reduce((acc, cur) => acc + cur.TotalRevenue, 0);

  /*   useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      callApi(
        `daily-revenue-by-hour?day=${selectedDay}&month=${selectedMonth}&year=${selectedYear}`
      )
        .then((response) => setData(response.data))
        .catch((error) => console.error("Error fetching the data: ", error));
    }
  }, [selectedDay, selectedMonth, selectedYear]); */

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      // Emitting event to fetch data
      socket.emit("fetch-daily-revenue-by-hour", {
        year: selectedYear,
        month: selectedMonth,
        day: selectedDay,
      });

      // Listening for data response
      socket.on("daily-revenue-by-hour-data", (rows) => {
        setData(rows);
      });

      // Handling potential errors
      socket.on("daily-revenue-by-hour-error", ({ error }) => {
        console.error("Error fetching the data: ", error);
      });

      // Cleanup to avoid duplicate listeners
      return () => {
        socket.off("daily-revenue-by-hour-data");
        socket.off("daily-revenue-by-hour-error");
      };
    }
  }, [selectedDay, selectedMonth, selectedYear, socket]);

  useEffect(() => {
    callApi("available-years")
      .then((response) => {
        setAvailableYears(response);
      })
      .catch((error) => console.error("Error fetching years: ", error));

    callApi("available-months")
      .then((response) => {
        setAvailableMonths(response);
      })
      .catch((error) => console.error("Error fetching months: ", error));

    callApi("available-days")
      .then((response) => {
        setAvailableDays(response);
      })
      .catch((error) => console.error("Error fetching days: ", error));

    callApi("daily-revenue-by-hour").then((response) => {
      setData(response.data);
    });
  }, []);

  const renderCustomBarLabel = ({ x, y, width, height, value }) => {
    return (
      <text x={x + width / 2} y={y - 5} fill="#fff" textAnchor="middle">
        {value}
      </text>
    );
  };

  const barColors = [
    "#8884d8",
    "#83a6ed",
    "#8dd1e1",
    "#82ca9d",
    "#a4de6c",
    "#d0ed57",
    "#A0D9DB",
    "#83a6ed",
    "#8884d8",
    "#A3A5CE",
  ];

  const formatCurrency = (number) => {
    return new Intl.NumberFormat("cs-CZ", {
      style: "currency",
      currency: "CZK",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const renderDaySelector = () => (
    <Select
      value={selectedDay}
      onChange={setSelectedDay}
      style={{ width: 70 }}
      placeholder="Den"
    >
      {availableDays.map((day) => (
        <Select.Option key={day} value={day}>
          {day}
        </Select.Option>
      ))}
    </Select>
  );

  const renderMonthSelector = () => (
    <Select
      value={selectedMonth}
      onChange={setSelectedMonth}
      style={{ width: 70 }}
      placeholder="Měsíc"
    >
      {availableMonths.map((month) => (
        <Select.Option key={month} value={month}>
          {month}
        </Select.Option>
      ))}
    </Select>
  );

  const renderYearSelector = () => (
    <Select
      value={selectedYear}
      onChange={setSelectedYear}
      style={{ width: 90 }}
      placeholder="Rok"
    >
      {availableYears.map((year) => (
        <Select.Option key={year} value={year}>
          {year}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <div className="actualChartbox">
      <div className="headerWithSelectors">
        <h1 style={{ fontSize: "24px", marginBottom: "5px" }}>
          Hodinová tržba
        </h1>{" "}
        <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          {renderDaySelector()}
          {renderMonthSelector()}
          {renderYearSelector()}
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          padding: "0px 0",
        }}
      >
        Celkem: {formatCurrency(totalRevenue)}
      </div>
      {data && data.length > 0 ? (
        <>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 0,
              }}
            >
              <XAxis dataKey="Hour" tickFormatter={(tick) => `${tick}h`} />
              <YAxis />
              <Bar dataKey="TotalRevenue">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={barColors[index % barColors.length]}
                  />
                ))}
                <LabelList
                  dataKey="TotalRevenue"
                  content={(props) =>
                    renderCustomBarLabel({
                      ...props,
                      value: formatCurrency(props.value),
                    })
                  }
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            userSelect: "none",
          }}
        >
          Zatím neproběhli žádné prodeje.
        </div>
      )}
    </div>
  );
};

export default ActualChartbox;
