import React, { useEffect, useState } from "react";
import { Drawer, Button, Table, Tabs } from "antd";
import callApi from "../../ApiCaller";

const DocumentDrawerDetails = ({
  isDrawerVisible,
  closeDrawer,
  selectedDocument,
}) => {
  const [data, setData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const { TabPane } = Tabs;

  useEffect(() => {
    if (selectedDocument && typeof selectedDocument === "object") {
      // Použití callApi pro načítání dokumentů
      callApi(`documents/${selectedDocument.id}`)
        .then((data) => setData(data))
        .catch((error) => console.error("Error fetching data:", error));

      // Použití callApi pro načítání plateb
      callApi(`payments/${selectedDocument.id}`)
        .then((data) => setPaymentData(data))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [selectedDocument]);

  const formatCurrency = (price) => {
    return price.toLocaleString("cs-CZ", {
      style: "currency",
      currency: "CZK",
    });
  };

  const calculateTotalRevenues = (data) => {
    let totalRevenue = 0;
    let totalRevenueWithoutTax = 0;
    let totalRevenueTax = 0;
    let totalProductDiscount = 0;
    let documentDiscount = 0;

    data.forEach((item) => {
      // Výpočet slevy na úrovni položky
      let productDiscount = 0;
      if (item.DiscountType === 0) {
        // Pokud je typ slevy 0 (procentuální), přepočítejte na částku z původní ceny
        const originalPrice =
          parseFloat(item.Total) / (1 - parseFloat(item.Discount) / 100);
        productDiscount = originalPrice - parseFloat(item.Total);
      } else {
        // Pokud je typ slevy 1 (částka), použijte ji přímo
        productDiscount = parseFloat(item.Discount);
      }
      // Cena s DPH pro položku
      const priceWithTax = parseFloat(item.Price || 0);
      // Cena bez DPH pro položku
      const priceWithoutTax = parseFloat(item.PriceBeforeTax || 0);
      // Množství položky
      const quantity = parseFloat(item.Quantity || 0);
      // Celková cena pro položku
      const itemTotal = priceWithTax * quantity;
      // Sleva pro položku
      const itemDiscountValue = productDiscount;
      // Celková cena pro položky bez DPH
      const itemTotalWithoutTax = priceWithoutTax * quantity;

      // Daň pro položky
      const itemTax = itemTotal - itemTotalWithoutTax;

      // Akumulace slevy na úrovni položek
      totalRevenue += itemTotal;
      totalRevenueWithoutTax += itemTotalWithoutTax;
      totalRevenueTax += itemTax;
      totalProductDiscount += itemDiscountValue;

      // Přidání slevy na úrovni dokumentu, pokud existuje
      documentDiscount = item.DocumentDiscount || 0;
    });
    const totalDiscount = totalProductDiscount + documentDiscount;

    // Celková cena včetně slevy na úrovni dokumentu
    const totalRevenueWithDocumentDiscount = totalRevenue - totalDiscount;

    return {
      totalProductDiscount: totalProductDiscount,
      totalRevenue: totalRevenueWithDocumentDiscount,
      totalDiscount: totalDiscount, // Celková sleva jako součet slevy na úrovni dokumentu a slevy na úrovni položek
      totalRevenueWithoutTax: totalRevenueWithoutTax - totalDiscount,
      totalRevenueTax,
      documentDiscount,
    };
  };

  const calculateTotalPayments = (paymentData) => {
    return paymentData.reduce((acc, payment) => acc + payment.Amount, 0);
  };
  const {
    totalProductDiscount,
    documentDiscount,
    totalDiscount,
    totalRevenue,
    totalRevenueWithoutTax,
    totalRevenueTax,
  } = calculateTotalRevenues(data);

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hh = date.getHours().toString().padStart(2, "0");
    const mm = date.getMinutes().toString().padStart(2, "0");
    const ss = date.getSeconds().toString().padStart(2, "0");
    const dd = date.getDate().toString().padStart(2, "0");
    const mmStr = (date.getMonth() + 1).toString().padStart(2, "0"); // Měsíce jsou indexované od 0
    const yyyy = date.getFullYear();

    return ` ${dd}.${mmStr}.${yyyy} v ${hh}:${mm}:${ss}`;
  };

  const firstTableColumns = [
    {
      title: "Název",
      dataIndex: "ProductName",
      sorter: (a, b) => a.ProductName.localeCompare(b.ProductName),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Velikost",
      dataIndex: "MeasurementUnit",
      sorter: (a, b) => a.MeasurementUnit.localeCompare(b.MeasurementUnit),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Množství",
      dataIndex: "Quantity",
      sorter: (a, b) => a.Quantity - b.Quantity,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Cena bez DPH",
      dataIndex: "PriceBeforeTax",
      render: (price) => formatCurrency(price),
      sorter: (a, b) => a.PriceBeforeTax - b.PriceBeforeTax,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "DPH",
      dataIndex: "Tax",
      sorter: (a, b) => a.Tax - b.Tax,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Cena vč. DPH",
      dataIndex: "Price",
      render: (price) => formatCurrency(price),
      sorter: (a, b) => a.Price - b.Price,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Sleva (Kč)",
      dataIndex: "Discount",
      render: (text, record) => {
        if (record.DiscountType === 0) {
          // Pokud je typ slevy 0 (procentuální), přepočítejte na částku z původní ceny
          const originalPrice =
            parseFloat(record.Total) / (1 - parseFloat(text) / 100);
          return `${(originalPrice - parseFloat(record.Total)).toFixed(2)} Kč`;
        }
        return `${text} Kč`;
      },
      sorter: (a, b) => {
        if (a.DiscountType === 0 && b.DiscountType === 0) {
          // Pokud je typ slevy 0 (procentuální), porovnávejte podle částky
          const originalPriceA =
            parseFloat(a.Total) / (1 - parseFloat(a.Discount) / 100);
          const originalPriceB =
            parseFloat(b.Total) / (1 - parseFloat(b.Discount) / 100);
          return originalPriceA - originalPriceB;
        }
        return parseFloat(a.Discount) - parseFloat(b.Discount);
      },
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Celkem vč. DPH",
      dataIndex: "Total",
      render: (price) => formatCurrency(price),
      sorter: (a, b) => a.Total - b.Total,
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  const secondTableColumns = [
    {
      title: "Datum",
      dataIndex: "Date",
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("cs-CZ", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        return formattedDate;
      },
    },
    {
      title: "Částka",
      dataIndex: "Amount",
      render: (amount) => formatCurrency(amount), // Přidáno render pro formátování měny
    },
    {
      title: "Typ Platby",
      dataIndex: "PaymentTypeName",
    },
  ];

  return (
    <Drawer
      title="Detail dokladu"
      placement="right"
      closable={true}
      onClose={closeDrawer}
      open={isDrawerVisible} // 'open' prop has been changed to 'visible'
      width={800}
    >
      <div>
        <h2>Číslo dokladu: {selectedDocument && selectedDocument.Number}</h2>
        <p>
          Vytvořeno:{" "}
          {selectedDocument && formatDateTime(selectedDocument.DateCreated)}
        </p>
        <p>
          Upraveno:{" "}
          {selectedDocument && formatDateTime(selectedDocument.DateUpdated)}
        </p>
        <br />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Položky dokladu" key="1">
            <Table
              dataSource={data}
              columns={firstTableColumns}
              footer={() => (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ flex: 1 }}>
                      <p>Sleva na doklad:</p>
                    </div>
                    <div style={{ textAlign: "right", flex: 1 }}>
                      <p>{formatCurrency(documentDiscount)}</p>
                    </div>
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 1 }}>
                      <b>Celková sleva:</b>
                    </div>
                    <div style={{ textAlign: "right", flex: 1 }}>
                      <b>{formatCurrency(totalDiscount)}</b>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ flex: 1 }}>
                      <p>Sleva na produkty:</p>
                    </div>
                    <div style={{ textAlign: "right", flex: 1 }}>
                      <p>{formatCurrency(totalProductDiscount)}</p>
                    </div>
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 1 }}>
                      <b>Celková tržba bez DPH:</b>
                    </div>
                    <div style={{ textAlign: "right", flex: 1 }}>
                      <b>{formatCurrency(totalRevenueWithoutTax)}</b>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 1 }}></div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 1 }}>
                      <b>Celkové DPH:</b>
                    </div>
                    <div style={{ textAlign: "right", flex: 1 }}>
                      <b>{formatCurrency(totalRevenueTax)}</b>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 1 }}>
                      <b>Celková tržba:</b>
                    </div>
                    <div style={{ textAlign: "right", flex: 1 }}>
                      <b>{formatCurrency(totalRevenue)}</b>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 1 }}></div>
                  </div>
                </div>
              )}
            />
          </TabPane>
          <TabPane tab="Platby" key="2">
            <Table
              dataSource={paymentData}
              columns={secondTableColumns}
              footer={() => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <b>Celkem zaplaceno: </b>
                  <b style={{ marginLeft: "50px" }}>
                    {formatCurrency(calculateTotalPayments(paymentData))}
                  </b>
                </div>
              )}
            />
          </TabPane>
        </Tabs>
        <br />
        <Button type="primary">Uložit</Button>
      </div>
    </Drawer>
  );
};

export default DocumentDrawerDetails;
