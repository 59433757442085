import React, { useState, useEffect } from "react";
import callApi from "../../ApiCaller";

import "./serverStatusBar.scss";

const Status = ({ status, name }) => (
  <div style={{ marginRight: "20px" }}>
    <span className="statusIcon">{status === "online" ? "🟢 " : "🔴 "}</span>
    <span>{name}</span>
  </div>
);

const ServerStatusBar = () => {
  const [processStatuses, setProcessStatuses] = useState({
    SqliteDatabaseChecker: "offline",
    RestartServer: "offline",
    DashboardServer: "offline",
  });

  useEffect(() => {
    const fetchProcessStatuses = async () => {
      try {
        const statuses = await callApi("check-processes");
        setProcessStatuses(statuses);
      } catch (error) {
        console.error("Error fetching process statuses:", error);
      }
    };

    fetchProcessStatuses();
    const intervalId = setInterval(fetchProcessStatuses, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="serverStatusBar">
      <div className="icons">
        <div className="process-status-indicators">
          <Status
            status={processStatuses.SqliteDatabaseChecker}
            name={"Sync"}
          />
          <Status status={processStatuses.RestartServer} name={"Restart"} />
          <Status
            status={processStatuses.DashboardServer}
            name={"Web Server"}
          />
        </div>
      </div>
    </div>
  );
};

export default ServerStatusBar;
