import React from "react";
import InventoryForm from "../../components/InventoryForm/InventoryForm";

const Storage = () => {
  return (
    <div>
      <InventoryForm />
    </div>
  );
};

export default Storage;
