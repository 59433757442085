// shellyApi.js
import axios from 'axios';

const BASE_URL = 'https://shelly-87-eu.shelly.cloud';
const AUTH_KEY = process.env.REACT_APP_SHELLY_API_KEY;

const getDeviceStatus = async (deviceId) => {
    try {
        const response = await axios.get(`${BASE_URL}/device/status`, {
            params: {
                id: deviceId,
                auth_key: AUTH_KEY
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching device status:", error);
       
    }
};

const controlRelay = async (deviceId, channel, turnState) => {
    try {
        const params = new URLSearchParams();
        params.append('id', deviceId);
        params.append('auth_key', AUTH_KEY);
        params.append('channel', channel);
        params.append('turn', turnState);

        const response = await axios.post(`${BASE_URL}/device/relay/control`, params);
        if (response.data && response.data.isok) {
            return response.data;
        } else {
            throw new Error("Failed to toggle device state");
        }
    } catch (error) {
        console.error("Error controlling relay:", error);
        throw error; 
    }
};



export { getDeviceStatus, controlRelay };
