import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <span>Lootea ©2021 - 2025. All Rights Reserved</span>
    </div>
  );
};

export default Footer;
