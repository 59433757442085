import React, { useState, useEffect } from "react";
import { LogoutOutlined, DownOutlined } from "@ant-design/icons";
import { notification, Dropdown, Menu as AntMenu } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "oidc-react";
import callApi from "../../ApiCaller";
import "./navbar.scss";

const Navbar = ({ setRole }) => {
  const auth = useAuth();
  const idToken = auth.userData?.id_token;
  const [isClicked, setIsClicked] = useState(false);
  const [processStatuses, setProcessStatuses] = useState({
    SqliteDatabaseChecker: "offline",
    RestartServer: "offline",
    DashboardServer: "offline",
  });
  const [selectedRole, setSelectedRole] = useState("Admin"); // Výchozí role

  const userProfile = auth.userData?.profile;
  const isEmployee = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.employee);
  const isInvestor = userProfile?.[
    "urn:zitadel:iam:org:project:242942895919755267:roles"
  ]?.some((role) => role.investor);

  // Definice ID a odpovídajících URL adres pro avatary
  const avatars = {
    // Patrik Dinh
    "242940328150069251":
      "https://security.lootea.cz/assets/v1/242938139277029379/users/242940328150069251/avatar?v=064e0dcd117ced99acb630975f444f3d",

    // Tomáš Dinh
    "242940199183609859":
      "https://security.lootea.cz/assets/v1/242938139277029379/users/242940199183609859/avatar?v=d6ffa13682aac8d098b89879daa625fd",

    // Viet Dinh
    "243008945604554755":
      "https://security.lootea.cz/assets/v1/242940750818471939/users/243008945604554755/avatar?v=74759bd508bf3ad547246645093e6465",

    // Tereza Hraníčková
    "243010317913714691":
      "https://security.lootea.cz/assets/v1/242940750818471939/users/243010317913714691/avatar?v=a5dea96687b12ce93d811723684a9cf3",

    // Maria Gureshova
    "243010092478263299":
      "https://security.lootea.cz/assets/v1/242940750818471939/users/243010092478263299/avatar?v=74759bd508bf3ad547246645093e6465",

    // Bao Anh Luong
    "260166797913780227":
      "https://security.lootea.cz/assets/v1/242940750818471939/users/260166797913780227/avatar?v=2b92dccdbc2b21d4930e20f54e793f1f",
  };

  useEffect(() => {
    const fetchProcessStatuses = async () => {
      try {
        const statuses = await callApi("check-processes");
        setProcessStatuses(statuses);
      } catch (error) {
        console.error("Error fetching process statuses:", error);
      }
    };

    fetchProcessStatuses();
    const intervalId = setInterval(fetchProcessStatuses, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const handleSignOut = async () => {
    try {
      await auth.signOut(); // Odhlášení uživatele

      // Zobrazte notifikaci o úspěšném odhlášení
      notification.success({
        message: "Úspěšné odhlášení",
        description: "Byli jste úspěšně odhlášeni ze systému.",
        placement: "bottomRight",
      });

      window.location.href =
        "https://security.lootea.cz/oidc/v1/end_session" +
        "?id_token_hint=" +
        encodeURIComponent(idToken) +
        "&post_logout_redirect_uri=" +
        encodeURIComponent("https://admin.lootea.cz");
    } catch (error) {
      // Zobrazte notifikaci v případě chyby
      notification.error({
        message: "Chyba při odhlášení",
        description: "Nastala chyba při pokusu o odhlášení.",
        placement: "bottomRight",
      });
    }
  };

  const handleRestart = async () => {
    setIsClicked(true);
    try {
      const result = await callApi("restart");
      if (result.error) {
        setIsClicked(true);
        console.log("Server Restart: Failed", result.message);
        // notification.error({
        //   message: "Restart Failed",
        //   description:
        //     "The server could not be restarted. Please try again later.",
        //   placement: "bottomRight",
        // });
      } else {
        setIsClicked(true);
        console.log("Server Restart: Successful");
        notification.success({
          message: "Server Restart",
          description: "Server has been successfully restarted.",
          placement: "bottomRight",
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const userAvatarUrl = avatars[auth.userData?.profile?.sub];
  const defaultAvatarUrl = "./user.svg";

  const roleMenu = (
    <AntMenu
      onClick={(e) => {
        setRole(e.key);
        setSelectedRole(e.key);
      }}
    >
      <AntMenu.Item key="Admin">Admin</AntMenu.Item>
      <AntMenu.Item key="Employee">Employee</AntMenu.Item>
      <AntMenu.Item key="Investor">Investor</AntMenu.Item>
    </AntMenu>
  );

  return (
    <div className="navbar">
      <div className="logo">
        <Link to="/">
          <span>Lootea</span>
        </Link>
      </div>

      {!(isEmployee || isInvestor) && (
        <div className="icons">
          <Dropdown overlay={roleMenu} trigger={["click"]}>
            <div className="role-switcher">
              <span>{selectedRole}</span> <DownOutlined />
            </div>
          </Dropdown>
          <div className="restartButton"></div>
          <div className="iconLinks">
            <img
              src={
                isClicked ? "/restartButtonPushed.webp" : "/restartButton.webp"
              }
              onClick={handleRestart}
              className={`restart-button ${isClicked ? "clicked" : ""}`}
              alt="restart button"
            />
            <a
              href="https://quickconnect.to/patrikdinh"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: 30, height: 30 }}
                src="./logo/synology-logo.png"
                alt="Synology"
              />
            </a>
            <a
              href="https://portal.comgate.cz/cs/prihlaseni"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: 30, height: 30 }}
                src="./logo/comgate-logo.png"
                alt="Comgate"
              />
            </a>
            <a
              href="https://dashboard.nesnezeno.eco/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: 30, height: 30 }}
                src="./logo/nesnezeno.png"
                alt="Nesnezeno"
              />
            </a>
            <a
              href="https://merchant.wolt.com/venue/lootea/insights"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: 30, height: 30 }}
                src="./logo/wolt.webp"
                alt="Wolt"
              />
            </a>
            <a
              href="https://www.mojedatovaschranka.cz/as/login?type=mep&uri=https%3A%2F%2Fwww.mojedatovaschranka.cz%2Fportal%2FISDS%2F"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ width: 30 }}
                src="./logo/datova-schranka.png"
                alt="Datova Schránka"
              />
            </a>
          </div>
        </div>
      )}
      {!(isEmployee || isInvestor) ? (
        <img
          src={userAvatarUrl || defaultAvatarUrl}
          alt="User Avatar"
          className="avatar sm-none"
        />
      ) : (
        <img
          src={userAvatarUrl || defaultAvatarUrl}
          alt="User Avatar"
          className="avatar"
        />
      )}

      {!(isEmployee || isInvestor) ? (
        <div className="user  sm-none">
          <span className="user">
            <strong className="username">
              {auth.userData?.profile?.nickname
                ? auth.userData?.profile?.nickname
                : `${auth.userData?.profile?.given_name} ${auth.userData?.profile?.family_name}`}
            </strong>
          </span>
          <span
            style={{
              color: "gold",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {
              Object.keys(
                auth.userData?.profile?.[
                  "urn:zitadel:iam:org:project:242942895919755267:roles"
                ]?.[0] ?? {}
              )[0]
            }
          </span>
        </div>
      ) : (
        <div className="user">
          <span className="user">
            <strong className="username">
              {auth.userData?.profile?.nickname
                ? auth.userData?.profile?.nickname
                : `${auth.userData?.profile?.given_name} ${auth.userData?.profile?.family_name}`}
            </strong>
          </span>
          <span
            style={{
              color: "gold",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {
              Object.keys(
                auth.userData?.profile?.[
                  "urn:zitadel:iam:org:project:242942895919755267:roles"
                ]?.[0] ?? {}
              )[0]
            }
          </span>
        </div>
      )}
      <button onClick={handleSignOut} className="logout-button">
        <LogoutOutlined className="logout-icon" />
      </button>
    </div>
  );
};

export default Navbar;
